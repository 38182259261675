import React, { useState } from 'react';
import './NavBar.css';
import comingSoonImage from '../../assets/images/coming_soon.png';
import { ReactComponent as EarnIcon } from '../../assets/icons/earn.svg';
import { ReactComponent as FriendsIcon } from '../../assets/icons/friends.svg';
import { ReactComponent as TapIcon } from '../../assets/icons/tap.svg';
import { ReactComponent as MineIcon } from '../../assets/icons/mine.svg';
import { ReactComponent as AirdropIcon } from '../../assets/icons/airdrop.svg';

const NavBar = ({ selected, onSelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navItems = [
    { name: 'Earn', Icon: EarnIcon },
    { name: 'Friends', Icon: FriendsIcon },
    { name: 'Tap', Icon: TapIcon },
    { name: 'Mine', Icon: MineIcon },
    { name: 'Soon...', Icon: AirdropIcon, disabled: true, noPageSwitch: true },
  ];

  const handleItemClick = (item) => {
    if (item.disabled && item.noPageSwitch) {
      setIsModalOpen(true);
    } else if (selected !== item.name) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
      }
      onSelect(item.name);
    }
  };

  return (
    <div className="navbar">
      {navItems.map((item) => (
        <div
          key={item.name}
          className={`nav-item ${selected === item.name && !item.noPageSwitch ? 'selected' : item.disabled ? 'disabled' : 'unselected'}`}
          onClick={() => handleItemClick(item)}
        >
          <item.Icon className="nav-icon" />
          <span className="nav-text">{item.name}</span>
        </div>
      ))}

      {isModalOpen && (
        <div className="nav-modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="nav-modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={comingSoonImage} alt="Coming Soon" className="nav-modal-image" />
            <p className="nav-modal-text">Coming Soon...</p>
            <button className="nav-modal-button" onClick={() => setIsModalOpen(false)}>
              <span className="nav-button-text">I Will Wait</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
