const env = "prod";

const configMap = {
  dev: {
    apiUrl: 'api-dev.thala.io/',
    botName: 'zallet_bot/zallet_test',
  },
  prod: {
    apiUrl: 'api.thala.io/',
    botName: 'thala_ScootAround_bot/thala',
  },
};

const config = configMap[env] || configMap.dev;

export default config;