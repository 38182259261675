import React, { useState, useEffect, useRef, useContext } from 'react';
import '../invite_friends/invite_friends.css';
import coinIcon from '../../assets/images/coin.png';
import refreshIcon from '../../assets/icons/refresh.svg';
import inviteFriendsImage from '../../assets/images/invite_friends.png';
import headshot from '../../assets/images/headshot.png';
import copyIcon from '../../assets/icons/copy.svg';
import tickSquareIcon from '../../assets/icons/tick_square.svg';
import closeIcon from '../../assets/icons/close.svg';
import copySound from '../../assets/sound_effect/copy.mp3';
import { isTMA } from '@tma.js/sdk';
import inviteFriendsApi from '../../pages/invite_friends/invite_friends_api';
import { GameContext } from '../game/game_provider';
import { MusicContext } from '../../music_provider';
import config from '../../config';

const InviteList = ({ friends }) => {
    return (
        <div className="invite-list">
            {friends.length === 0 ? (
                <div className="invite-list-empty">
                    <img src={inviteFriendsImage} alt="Invite Friends" className="invite-friends-image" />
                    <div className="invite-friends-text">Invite Friends</div>
                </div>
            ) : (
                friends.map(friend => (
                    <div key={friend.id} className="invite-list-item">
                        <div className="invite-list-item-content">
                            <img src={headshot} alt="Headshot" className="headshot" />
                            <div className="friend-info">
                                <span className="friend-name">{friend.name}</span>
                                <span className="friend-reward">+5000</span>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

const InviteFriends = () => {
    const { user } = useContext(GameContext);
    const { setMusic, isMusicPlaying } = useContext(MusicContext);
    const [friends, setFriends] = useState([]);
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const audioRef = useRef(null);

    const fetchInvitees = async () => {
        try {
            const response = await inviteFriendsApi.getInvitees();
            if (response.result) {
                const invitees = response.result.map(invitee => ({
                    id: invitee.id,
                    name: `${invitee.first_name} ${invitee.last_name}`.trim()
                }));
                setFriends(invitees);
            } else {
                setFriends([]);
            }
        } catch (error) {
            console.error('Error fetching invitees:', error);
        }
    };

    useEffect(() => {
        fetchInvitees();
    }, []);

    useEffect(() => {
        setMusic(require('../../assets/bgm/invite_friends.mp3'));
    }, [setMusic]);

    useEffect(() => {
        if (showCopyPopup) {
            if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                const vibratePattern = ['medium', 'medium', 'medium', 'medium', 'medium'];
                vibratePattern.forEach((type, index) => {
                    setTimeout(() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred(type);
                    }, index * 100);
                });
            }
            if (isMusicPlaying && audioRef.current) {
                audioRef.current.play();
            }
        }
    }, [showCopyPopup, isMusicPlaying]);

    const handleInviteClick = () => {
        try {
            if (isTMA() && window.Telegram && window.Telegram.WebApp) {
                const botName = config.botName;
                const message = `https://t.me/${botName}?startapp=${user.user_id}\nJoin me in Thala Scoot Around and start your adventure! 🏞️🚀\n\nStart exploring new countries, level up your traveler, and collect amazing rewards. Don't miss out on the fun and rewards!🌟\n`;
                window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(message)}`);
            } else {
                console.log('This feature is only available in Telegram.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleCopyClick = () => {
        try {
            const botName = config.botName;
            navigator.clipboard.writeText(`https://t.me/${botName}?startapp=${user.user_id}\nJoin me in Thala Scoot Around and start your adventure! 🏞️🚀\n\nStart exploring new countries, level up your traveler, and collect amazing rewards. Don't miss out on the fun and rewards!🌟\n`)
                .then(() => {
                    console.log('Text copied to clipboard');
                    setShowCopyPopup(true);
                    setTimeout(() => {
                        setShowCopyPopup(false);
                    }, 3000);
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };


    const handleClosePopup = () => {
        setShowCopyPopup(false);
    };

    return (
        <div className="invite-friends">
            <div className="column"></div>
            <div className="invite-title">Invite Friends!</div>
            <div className="invite-card">
                <div className="invite-content">
                    <img src={coinIcon} alt="Coin Icon" className="coin-icon" />
                    <div className="invite-text">
                        <div className="invite-header">Invite a friend</div>
                        <div className="invite-subheader">
                            <span className="highlight-text">+ 5,000</span> for you and your friend
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '24px' }}></div>
            <div className="friends-list">
                <span className="friends-text">List of your friends ({friends.length})</span>
                <img src={refreshIcon} alt="Refresh Icon" className="refresh-icon" onClick={fetchInvitees} />
            </div>
            <div style={{ height: '24px' }}></div>
            <div className="invite-list-wrapper">
                <InviteList friends={friends} />
            </div>
            <div style={{ height: '16px' }}></div>
            <div className="invite-buttons">
                <button className="invite-friend-button" onClick={handleInviteClick}>
                    <span className="text">Invite a friend</span>
                </button>
                <div style={{ width: '16px' }}></div>
                <button className="copy-button" onClick={handleCopyClick}>
                    <img src={copyIcon} alt="Copy Icon" className="copy-icon" />
                </button>
            </div>
            <div style={{ height: '22px' }}></div>
            <audio ref={audioRef} src={copySound} />
            {showCopyPopup && (
                <div className={`copy-popup ${showCopyPopup ? 'show' : ''}`}>
                    <div className="popup-content">
                        <img src={tickSquareIcon} alt="Tick Icon" className="popup-icon" />
                        <span className="popup-text">Text copied</span>
                        <div style={{ flex: 1 }}></div>
                        <img src={closeIcon} alt="Close Icon" className="popup-close" onClick={handleClosePopup} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default InviteFriends;