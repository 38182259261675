import React, { useContext, useState, useRef, useEffect } from 'react';
import './equipment.css';
import coinImage from '../../assets/images/coin.png';
import { GameContext } from '../game/game_provider';
import { MusicContext } from '../../music_provider';
import closeIcon from '../../assets/icons/close.svg';
import mineApi from './mine_api';
import tickSquareIcon from '../../assets/icons/tick_square.svg';
import upgradeSound from '../../assets/sound_effect/copy.mp3';

const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
};

const Equipment = ({ type, data }) => {
  const { user, setUser } = useContext(GameContext);
  const { setMusic, isMusicPlaying } = useContext(MusicContext);
  const equipments = user.equipments_list[type];
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const audioRef = useRef(null);

  const handleEquipmentClick = (item) => {
    if (!item || !data[item.name]) {
      console.error('Invalid equipment selected:', item);
      return;
    }
    setSelectedEquipment(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpgrade = async () => {
    try {
      const currentLevel = selectedEquipment.level;
      const nextLevelXP = data[selectedEquipment.name].levels[currentLevel].nextLevelXP;
      const passiveIncomeIncrease = data[selectedEquipment.name].levels[currentLevel].passiveIncome;

      if (user.token_amount >= nextLevelXP) {
        await mineApi.levelUpEquipment(selectedEquipment.name);

        setUser((prevUser) => {
          const updatedEquipmentsList = {
            ...prevUser.equipments_list,
            [type]: prevUser.equipments_list[type].map((item) =>
              item.name === selectedEquipment.name
                ? { ...item, level: item.level + 1 }
                : item
            ),
          };

          return {
            ...prevUser,
            token_amount: prevUser.token_amount - nextLevelXP,
            mining_rate: prevUser.mining_rate + passiveIncomeIncrease,
            equipments_list: updatedEquipmentsList,
          };
        });

        setShowUpgradePopup(true);
        setTimeout(() => {
          setShowUpgradePopup(false);
        }, 3000);
        setShowModal(false);
      } else {
        console.log('Insufficient tokens for upgrade');
      }
    } catch (error) {
      console.error('Error upgrading equipment:', error);
    }
  };

  useEffect(() => {
    setMusic(require('../../assets/bgm/invite_friends.mp3'));
  }, [setMusic]);

  useEffect(() => {
    setSelectedEquipment(null);
    setShowModal(false);
  }, [type]);

  useEffect(() => {
    if (showUpgradePopup) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
        const vibratePattern = ['medium', 'medium', 'medium', 'medium', 'medium'];
        vibratePattern.forEach((type, index) => {
          setTimeout(() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred(type);
          }, index * 100);
        });
      }
      if (isMusicPlaying && audioRef.current) {
        audioRef.current.play();
      }
    }
  }, [showUpgradePopup, isMusicPlaying]);

  const renderModalContent = () => {
    if (!selectedEquipment || !data[selectedEquipment.name]) return null;

    const equipment = data[selectedEquipment.name];
    const currentLevel = selectedEquipment.level;
    const nextLevelXP = equipment.levels[currentLevel].nextLevelXP;

    const isUpgradeDisabled = user.token_amount < nextLevelXP;

    return (
      <div className="equipment-modal-content">
        <img src={closeIcon} alt="close" className="equipment-modal-close" onClick={handleCloseModal} />
        <img
          src={equipment.icon}
          alt={selectedEquipment.name}
          className="equipment-modal-icon"
        />
        <div className="equipment-modal-title">{selectedEquipment.name}</div>
        <div className="equipment-modal-description">{equipment.description}</div>
        <div className="equipment-modal-details">
          <div className="equipment-modal-profit-text">
            Profit per hour
          </div>
          <div className="equipment-modal-profit-value">
            <img src={coinImage} alt="coin" className="coin coin-small" />
            +{formatNumber(equipment.levels[currentLevel].passiveIncome)}
          </div>
          <div className="equipment-modal-next-level-xp">
            <img src={coinImage} alt="coin" className="coin coin-large" />
            {formatNumber(nextLevelXP)}
          </div>
        </div>
        <button
          className={`equipment-modal-button ${isUpgradeDisabled ? 'disabled' : ''}`}
          onClick={handleUpgrade}
          disabled={isUpgradeDisabled}
        >
          {isUpgradeDisabled ? 'Insufficient Tokens' : 'Upgrade'}
        </button>
      </div>
    );
  };

  return (
    <div className="equipment-grid">
      {equipments.map((item) => {
        const equipment = data[item.name];
        const currentLevel = item.level;

        return (
          <div
            key={item.name}
            className="equipment-item"
            onClick={() => handleEquipmentClick(item)}
          >
            <div className="equipment-header">
              <img
                src={equipment.icon}
                alt={item.name}
                className="equipment-icon"
              />
              <div className="equipment-details">
                <div className="equipment-name">
                  {item.name}
                </div>
                <div className="equipment-profit-text">
                  Profit per hour
                </div>
                <div className="equipment-profit-value">
                  +{formatNumber(equipment.levels[currentLevel].passiveIncome)}
                </div>
              </div>
            </div>
            <div className="equipment-separator"></div>
            <div className="equipment-level">
              <div className="level-label">
                lvl {currentLevel}
              </div>
              <div className="level-divider"></div>
              <img src={coinImage} alt="Coin" className="coin" />
              <div className="next-level-XP">
                {formatNumber(equipment.levels[currentLevel].nextLevelXP)}
              </div>
            </div>
          </div>
        );
      })}

      <div className={`equipment-modal-container ${showModal ? 'show' : ''}`}>
        {renderModalContent()}
      </div>

      {showUpgradePopup && (
        <div className="upgrade-popup show">
          <div className="upgrade-popup-content">
            <img src={tickSquareIcon} alt="Tick Icon" className="upgrade-popup-icon" />
            <span className="upgrade-popup-text">Upgrade Successful!</span>
            <div style={{ flex: 1 }}></div>
            <img src={closeIcon} alt="Close Icon" className="upgrade-popup-icon" onClick={() => setShowUpgradePopup(false)} />
          </div>
        </div>
      )}
      <audio ref={audioRef} src={upgradeSound} />
    </div>
  );
};

export { Equipment };
