// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideInFromLeft {
    0% {
      transform: translateX(-110%);
      opacity: 0;
    }
    80% {
      transform: translateX(20px);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(110%);
      opacity: 0;
    }
    80% {
      transform: translateX(-20px);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-110%);
      opacity: 0;
    }
    90% {
      transform: translateY(5px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(110%);
      opacity: 0;
    }
    80% {
      transform: translateY(-20px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes zoomInOut {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    90% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/animations.css"],"names":[],"mappings":"AAAA;IACI;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,2BAA2B;MAC3B,UAAU;IACZ;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,2BAA2B;MAC3B,UAAU;IACZ;IACA;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,0BAA0B;MAC1B,UAAU;IACZ;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,2BAA2B;MAC3B,UAAU;IACZ;IACA;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,mBAAmB;MACnB,UAAU;IACZ;IACA;MACE,qBAAqB;MACrB,UAAU;IACZ;IACA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["@keyframes slideInFromLeft {\n    0% {\n      transform: translateX(-110%);\n      opacity: 0;\n    }\n    80% {\n      transform: translateX(20px);\n      opacity: 1;\n    }\n    100% {\n      transform: translateX(0);\n    }\n  }\n  \n  @keyframes slideInFromRight {\n    0% {\n      transform: translateX(110%);\n      opacity: 0;\n    }\n    80% {\n      transform: translateX(-20px);\n      opacity: 1;\n    }\n    100% {\n      transform: translateX(0);\n    }\n  }\n  \n  @keyframes slideInFromTop {\n    0% {\n      transform: translateY(-110%);\n      opacity: 0;\n    }\n    90% {\n      transform: translateY(5px);\n      opacity: 1;\n    }\n    100% {\n      transform: translateY(0);\n    }\n  }\n  \n  @keyframes slideInFromBottom {\n    0% {\n      transform: translateY(110%);\n      opacity: 0;\n    }\n    80% {\n      transform: translateY(-20px);\n      opacity: 1;\n    }\n    100% {\n      transform: translateY(0);\n    }\n  }\n  \n  @keyframes zoomInOut {\n    0% {\n      transform: scale(0);\n      opacity: 0;\n    }\n    90% {\n      transform: scale(1.2);\n      opacity: 1;\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
