import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { getUserInfo } from './tap_game_api';
import { levelData } from './game_model';
import config from '../../config';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [user, setUser] = useState({
    user_id: "",
    user_level: 0,
    tap_to_earn_level: 0,
    energy_level: 0,
    mining_rate: 0,
    token_amount: 0,
    current_country: "",
    selected_countries: [],
    recharge_energy_times: 0,
    equipments_list: {
      inv: [],
      freel: [],
      supp: []
    },
    tasks: {
      daily_tasks: {
        sign_in_data: {
          last_sign_in_date: 0,
          consecutive_days: 0
        },
        thala_post_task: {
          last_post_date: 0
        }
      },
      single_tasks: {
        is_follow_FB: false,
        is_follow_IG: false,
        is_follow_X: false,
        is_join_tg_channel: false,
        is_register_thala: false
      }
    }
  });  
  const [energy, setEnergy] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [messageToSend, setMessageToSend] = useState(null);
  const energyRef = useRef(energy);
  const incrAmountRef = useRef(0);

  const addToIncrAmount = (amount) => {
    incrAmountRef.current += amount;
  };

  const sendMessageNow = useCallback(() => {
    if (!isLoading && user.user_id) {
      setMessageToSend({
        incr_amount: incrAmountRef.current,
        current_energy: energyRef.current
      });
      incrAmountRef.current = 0;
    }
  }, [isLoading, user.user_id, energyRef]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getUserInfo();
        if (response.code === 0) {
          const data = response.result;

          const equipmentsByType = data.equipments_list.reduce((acc, item) => {
            if (!acc[item.type]) {
              acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
          }, { inv: [], freel: [], supp: [] });

          const newUser = {
            user_id: data.user_id,
            user_level: data.user_level,
            tap_to_earn_level: data.tap_to_earn_level,
            energy_level: data.energy_level,
            mining_rate: data.mining_rate,
            token_amount: data.token_amount,
            current_country: data.current_country,
            selected_countries: data.selected_countries,
            recharge_energy_times: data.recharge_energy_times,
            equipments_list: equipmentsByType,
            tasks: data.tasks
          };

          console.log('fetchInitialData setUser:', newUser);
          setUser(newUser);
          setEnergy(data.current_energy);
        } else {
          console.error('Error fetching user info: ', response);
        }
      } catch (error) {
        console.error('Error fetching user info: ', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
  }, []);


  useEffect(() => {
    if (!isLoading) {
      const maxEnergy = levelData.energyRecovery[user.energy_level].maxEnergy;
      const recoveryRate = levelData.energyRecovery[user.energy_level].recoveryRate;

      const intervalId = setInterval(() => {
        setEnergy(prevEnergy => Math.min(prevEnergy + recoveryRate, maxEnergy));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [user.energy_level, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      const miningRatePerSecond = user.mining_rate / 3600;
      const intervalId = setInterval(() => {
        setUser(prevUser => {
          const newTokenAmount = prevUser.token_amount + miningRatePerSecond;

          incrAmountRef.current += miningRatePerSecond;

          return {
            ...prevUser,
            token_amount: newTokenAmount
          };
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [user.mining_rate, isLoading]);

  useEffect(() => {
    console.log('User state updated:', user);
  }, [user]);

  useEffect(() => {
    energyRef.current = energy;
  }, [energy]);

  useEffect(() => {
    if (!isLoading) {
      const intervalId = setInterval(() => {
        sendMessageNow();
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [isLoading, sendMessageNow]);

  useEffect(() => {
    if (messageToSend && !isLoading && user.user_id) {
      const ws = new WebSocket(`wss://${config.apiUrl}ws/telegram/game/${user.user_id}`);

      ws.onopen = () => {
        ws.send(JSON.stringify(messageToSend));
        console.log('Message sent:', messageToSend);
        setMessageToSend(null);
      };
    }
  }, [messageToSend, isLoading, user.user_id]);

  return (
    <GameContext.Provider value={{ user, setUser, energy, setEnergy, addToIncrAmount, sendMessageNow, isLoading }}>
      {children}
    </GameContext.Provider>
  );
};