import api from '../../api';

export const getInvitees = async () => {
  try {
    const invitees = await api.apiGet('telegram/users/invitees');
    return invitees;
  } catch (error) {
    console.error('Error fetching invitees:', error);
    throw error;
  }
};

const inviteFriendsApi = {
  getInvitees,
};

export default inviteFriendsApi;