import React, { createContext, useState, useRef, useEffect } from 'react';

export const MusicContext = createContext();

export const MusicProvider = ({ children }) => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const audioRef = useRef(new Audio());

  const toggleMusic = () => {
    if (isMusicPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    } else {
      audioRef.current.play().catch(error => console.log('Autoplay error:', error));
    }
    setIsMusicPlaying(!isMusicPlaying);
  };

  const fadeOut = (callback) => {
    let volume = audioRef.current.volume;
    const fadeInterval = setInterval(() => {
      if (volume > 0) {
        volume -= 0.01;
        audioRef.current.volume = Math.max(volume, 0);
      } else {
        clearInterval(fadeInterval);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        if (callback) callback();
      }
    }, 30);
  };

  const setMusic = (musicPath) => {
    fadeOut(() => {
      audioRef.current.src = musicPath;
      audioRef.current.loop = true;
      audioRef.current.volume = 0.2;
      if (isMusicPlaying) {
        audioRef.current.play().catch(error => console.log('Autoplay error:', error));
      }
    });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (isMusicPlaying) {
          audioRef.current.pause();
        }
      } else {
        if (isMusicPlaying) {
          audioRef.current.play().catch(error => console.log('Autoplay error:', error));
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isMusicPlaying]);

  return (
    <MusicContext.Provider value={{ isMusicPlaying, toggleMusic, setMusic }}>
      {children}
    </MusicContext.Provider>
  );
};
