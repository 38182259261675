import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import Menu from './menu';
import TapGame from './tap_game';
import Boost from './boost';
import './game.css';
import closeIcon from '../../assets/icons/close.svg';
import tickSquareIcon from '../../assets/icons/tick_square.svg';
import boostSound from '../../assets/sound_effect/boost.mp3';
import { countries } from './game_model';
import { GameContext } from './game_provider';
import { MusicContext } from '../../music_provider';

const Game = ({ setShowAppBar, setBackground }) => {
  const { user, isLoading } = useContext(GameContext);
  const [currentPage, setCurrentPage] = useState(null);
  const [transitioning, setTransitioning] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState('');
  const spotlightInRef = useRef(null);
  const spotlightOutRef = useRef(null);
  const audioRef = useRef(null);
  const {  isMusicPlaying } = useContext(MusicContext);


  useEffect(() => {
    if (!isLoading) {
      if (user.current_country && countries[user.current_country]) {
        handlePageChange('tapgame');
      } else {
        setCurrentPage('menu');
      }
    }
  }, [user.current_country, isLoading]);

  const handlePageChange = (newPage) => {
    setTransitioning(true);

    if (spotlightInRef.current) {
      spotlightInRef.current.beginElement();
    }

    setTimeout(() => {
      setCurrentPage(newPage);

      if (spotlightOutRef.current) {
        spotlightOutRef.current.beginElement();
      }

      setTimeout(() => {
        setTransitioning(false);
      }, 500);
    }, 500);
  };



  useEffect(() => {
    setShowAppBar(currentPage !== 'boost');
    if (currentPage === 'menu') {
      setBackground('menu');
    } else if (currentPage) {
      setBackground('default');
    }
  }, [currentPage, setShowAppBar, setBackground]);

  const handleBackButtonClick = useCallback(() => {
    if (currentPage === 'boost') {
      handlePageChange('tapgame');
    }
  }, [currentPage]);

  useEffect(() => {
    const telegramWebApp = window.Telegram?.WebApp;
    if (telegramWebApp) {
      if (currentPage === 'boost') {
        telegramWebApp.BackButton.show();
        telegramWebApp.BackButton.onClick(handleBackButtonClick);
      } else {
        telegramWebApp.BackButton.hide();
      }
    }

    return () => {
      if (telegramWebApp) {
        telegramWebApp.BackButton.offClick(handleBackButtonClick);
        telegramWebApp.BackButton.hide();
      }
    };
  }, [currentPage, handleBackButtonClick]);

  useEffect(() => {
    if (showPopup) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
        const vibratePattern = ['medium', 'medium', 'medium', 'medium', 'medium'];
        vibratePattern.forEach((type, index) => {
          setTimeout(() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred(type);
          }, index * 100);
        });
      }
      if (isMusicPlaying && audioRef.current) {
                audioRef.current.play();
            }
        }
    }, [showPopup, isMusicPlaying]);

  const handleLevelUpSuccess = (boosterText) => {
    setPopupText(boosterText);
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const renderPopup = () => (
    <div className={`level-up-popup ${showPopup ? 'show' : ''}`}>
      <div className="level-up-popup-content">
        <img src={tickSquareIcon} alt="Tick Icon" className="level-up-popup-icon" />
        <span className="level-up-popup-text">{popupText}</span>
        <div style={{ flex: 1 }}></div>
        <img src={closeIcon} alt="Close Icon" className="level-up-popup-close-icon" onClick={() => setShowPopup(false)} />
      </div>
    </div>
  );

  if (isLoading || currentPage === null) {
    return;
  }

  if (user.current_country && !countries[user.current_country]) {
    return <div>Country not supported</div>;
  }

  return (
    <div className={`game-container ${transitioning ? 'transition' : ''}`}>
      <svg className="transition-mask" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <mask id="spotlight">
            <rect width="100%" height="100%" fill="white" />
            <circle className="spotlight" cx="50%" cy="50%" r="75%" fill="black">
              <animate
                ref={spotlightInRef}
                attributeName="r"
                from="75%" to="0%"
                dur="0.5s"
                begin="indefinite"
                fill="freeze"
                id="spotlightAnimateIn"
              />
              <animate
                ref={spotlightOutRef}
                attributeName="r"
                from="0%" to="75%"
                dur="0.5s"
                begin="indefinite"
                fill="freeze"
                id="spotlightAnimateOut"
              />
            </circle>
          </mask>
        </defs>
        <rect width="100%" height="100%" fill="black" mask="url(#spotlight)" />
      </svg>
      <audio ref={audioRef} src={boostSound} />
      {currentPage === 'menu' && <Menu />}
      {currentPage === 'tapgame' && (
        <TapGame
          onBoost={() => handlePageChange('boost')}
          onMenu={() => handlePageChange('menu')}
        />
      )}
      {currentPage === 'boost' && (
        <Boost
          onBack={() => handlePageChange('tapgame')}
          onLevelUpSuccess={handleLevelUpSuccess}
        />
      )}
      {renderPopup()}
    </div>
  );
};

export default Game;
