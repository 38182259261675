export const dailyTasks = [
    { title: 'Daily reward', amounts: [500, 1000, 2500, 5000, 15000, 30000, 100000] },
    { title: 'Post on Thala', amount: '+150,000' }
];

export const taskList = [
    { title: 'Follow us on Facebook', amount: '+100,000', url: 'https://www.facebook.com/thala.io.official' },
    { title: 'Follow us on X', amount: '+100,000', url: 'https://x.com/Thala_io' },
    { title: 'Follow us on Instagram', amount: '+100,000', url: 'https://www.instagram.com/thala.io_official/' },
    { title: 'Join our TG Channel', amount: '+50,000', url: 'https://t.me/thalaverse_io' },
    { title: 'Download and Sign Up for Thala', amount: '+200,000', url: 'https://onelink.to/kgx38f' }
];