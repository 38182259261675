const levelData = {
  traveler: [
    { title: "Bronze", nextLevelXP: 5000, earnPerTap: 1 },
    { title: "Silver", nextLevelXP: 25000, earnPerTap: 2 },
    { title: "Gold", nextLevelXP: 100000, earnPerTap: 3 },
    { title: "Platinum", nextLevelXP: 1000000, earnPerTap: 4 },
    { title: "Diamond", nextLevelXP: 2000000, earnPerTap: 6 },
    { title: "Epic", nextLevelXP: 10000000, earnPerTap: 8 },
    { title: "Legendary", nextLevelXP: 50000000, earnPerTap: 10 },
    { title: "Master", nextLevelXP: 100000000, earnPerTap: 12 },
    { title: "Grandmaster", nextLevelXP: 1000000000, earnPerTap: 15 },
    { title: "Lord", nextLevelXP: null, earnPerTap: 18 },
  ],
  tapScore: [
    { nextLevelXP: 300, earnPerTap: 1 },
    { nextLevelXP: 600, earnPerTap: 2 },
    { nextLevelXP: 1800, earnPerTap: 3 },
    { nextLevelXP: 16000, earnPerTap: 4 },
    { nextLevelXP: 32000, earnPerTap: 6 },
    { nextLevelXP: 64000, earnPerTap: 8 },
    { nextLevelXP: 128000, earnPerTap: 10 },
    { nextLevelXP: 256000, earnPerTap: 12 },
    { nextLevelXP: 512000, earnPerTap: 15 },
    { nextLevelXP: 1024000, earnPerTap: 18 },
    { nextLevelXP: 2048000, earnPerTap: 21 },
  ],
  energyRecovery: [
    { nextLevelXP: 300, maxEnergy: 500, recoveryRate: 1 },
    { nextLevelXP: 600, maxEnergy: 1500, recoveryRate: 2 },
    { nextLevelXP: 1800, maxEnergy: 2500, recoveryRate: 3 },
    { nextLevelXP: 16000, maxEnergy: 3500, recoveryRate: 4 },
    { nextLevelXP: 32000, maxEnergy: 4500, recoveryRate: 5 },
    { nextLevelXP: 64000, maxEnergy: 5500, recoveryRate: 6 },
    { nextLevelXP: 128000, maxEnergy: 6500, recoveryRate: 7 },
    { nextLevelXP: 256000, maxEnergy: 7500, recoveryRate: 8 },
    { nextLevelXP: 512000, maxEnergy: 8500, recoveryRate: 9 },
    { nextLevelXP: 1024000, maxEnergy: 9500, recoveryRate: 10 },
    { nextLevelXP: 2048000, maxEnergy: 10500, recoveryRate: 11 },
  ],
};

const countries = {
  'Taiwan': {
    flag: require('../../assets/images/national_flag/taiwan.png'),
    map: require('../../assets/images/maps/taiwan.png'),
    bgm: require('../../assets/bgm/mapBgm/taiwan.mp3')
  },
  'United States': {
    flag: require('../../assets/images/national_flag/united_states.png'),
    map: require('../../assets/images/maps/united_states.png'),
    bgm: require('../../assets/bgm/mapBgm/united_states.mp3')
  },
  'United Kingdom': {
    flag: require('../../assets/images/national_flag/united_kingdom.png'),
    map: require('../../assets/images/maps/united_kingdom.png'),
    bgm: require('../../assets/bgm/mapBgm/united_kingdom.mp3')
  },
  'France': {
    flag: require('../../assets/images/national_flag/france.png'),
    map: require('../../assets/images/maps/france.png'),
    bgm: require('../../assets/bgm/mapBgm/france.mp3')
  },
  'Japan': {
    flag: require('../../assets/images/national_flag/japan.png'),
    map: require('../../assets/images/maps/japan.png'),
    bgm: require('../../assets/bgm/mapBgm/japan.mp3')
  },
  'Thailand': {
    flag: require('../../assets/images/national_flag/thailand.png'),
    map: require('../../assets/images/maps/thailand.png'),
    bgm: require('../../assets/bgm/mapBgm/thailand.mp3')
  },
  'Malaysia': {
    flag: require('../../assets/images/national_flag/malaysia.png'),
    map: require('../../assets/images/maps/malaysia.png'),
    bgm: require('../../assets/bgm/mapBgm/malaysia.mp3')
  },
  'Germany': {
    flag: require('../../assets/images/national_flag/germany.png'),
    map: require('../../assets/images/maps/germany.png'),
    bgm: require('../../assets/bgm/mapBgm/germany.mp3')
  },
  'Canada': {
    flag: require('../../assets/images/national_flag/canada.png'),
    map: require('../../assets/images/maps/canada.png'),
    bgm: require('../../assets/bgm/mapBgm/canada.mp3')
  },
  'South Korea': {
    flag: require('../../assets/images/national_flag/south_korea.png'),
    map: require('../../assets/images/maps/south_korea.png'),
    bgm: require('../../assets/bgm/mapBgm/south_korea.mp3')
  }
};


const investmentData = {
  'Staking': {
    description: 'Stake your tokens to earn daily rewards. The more you stake, the more you earn.',
    icon: require('../../assets/images/mine/inv/staking.png'),
    levels: [
      { nextLevelXP: 1000, passiveIncome: 100 },
      { nextLevelXP: 1530, passiveIncome: 150 },
      { nextLevelXP: 2350, passiveIncome: 250 },
      { nextLevelXP: 3620, passiveIncome: 400 },
      { nextLevelXP: 5550, passiveIncome: 650 },
      { nextLevelXP: 8520, passiveIncome: 1050 },
      { nextLevelXP: 13080, passiveIncome: 1600 },
      { nextLevelXP: 20080, passiveIncome: 2450 },
      { nextLevelXP: 30860, passiveIncome: 3700 },
      { nextLevelXP: 47350, passiveIncome: 5550 },
      { nextLevelXP: 72690, passiveIncome: 8300 },
      { nextLevelXP: 111570, passiveIncome: 12450 },
      { nextLevelXP: 171320, passiveIncome: 18650 },
      { nextLevelXP: 263030, passiveIncome: 27900 },
      { nextLevelXP: 404130, passiveIncome: 41700 },
      { nextLevelXP: 620510, passiveIncome: 62400 },
      { nextLevelXP: 952990, passiveIncome: 93300 },
      { nextLevelXP: 1463900, passiveIncome: 139500 },
      { nextLevelXP: 2248210, passiveIncome: 208600 },
      { nextLevelXP: 3450450, passiveIncome: 312000 },
    ]
  },
  'Yield Farming': {
    description: 'Provide liquidity in DeFi protocols to earn high-yield returns. Rewards vary with market conditions.',
    icon: require('../../assets/images/mine/inv/yield_farming.png'),
    levels: [
      { nextLevelXP: 800, passiveIncome: 80 },
      { nextLevelXP: 1220, passiveIncome: 120 },
      { nextLevelXP: 1870, passiveIncome: 200 },
      { nextLevelXP: 2880, passiveIncome: 320 },
      { nextLevelXP: 4420, passiveIncome: 520 },
      { nextLevelXP: 6780, passiveIncome: 840 },
      { nextLevelXP: 10370, passiveIncome: 1280 },
      { nextLevelXP: 15870, passiveIncome: 1960 },
      { nextLevelXP: 24330, passiveIncome: 2960 },
      { nextLevelXP: 37250, passiveIncome: 4440 },
      { nextLevelXP: 57070, passiveIncome: 6640 },
      { nextLevelXP: 87460, passiveIncome: 9840 },
      { nextLevelXP: 134090, passiveIncome: 14740 },
      { nextLevelXP: 205510, passiveIncome: 22080 },
      { nextLevelXP: 315030, passiveIncome: 33080 },
      { nextLevelXP: 482810, passiveIncome: 49640 },
      { nextLevelXP: 740240, passiveIncome: 74280 },
      { nextLevelXP: 1135370, passiveIncome: 111960 },
      { nextLevelXP: 1742400, passiveIncome: 168720 },
      { nextLevelXP: 2673090, passiveIncome: 254240 }
    ]
  },
  'DeFi Lending': {
    description: 'Lend your assets on DeFi platforms to earn interest. A safe way to generate passive income.',
    icon: require('../../assets/images/mine/inv/defi_lending.png'),
    levels: [
      { nextLevelXP: 900, passiveIncome: 90 },
      { nextLevelXP: 1370, passiveIncome: 140 },
      { nextLevelXP: 2100, passiveIncome: 230 },
      { nextLevelXP: 3220, passiveIncome: 360 },
      { nextLevelXP: 4930, passiveIncome: 580 },
      { nextLevelXP: 7570, passiveIncome: 940 },
      { nextLevelXP: 11570, passiveIncome: 1430 },
      { nextLevelXP: 17720, passiveIncome: 2180 },
      { nextLevelXP: 27320, passiveIncome: 3290 },
      { nextLevelXP: 42130, passiveIncome: 4930 },
      { nextLevelXP: 64900, passiveIncome: 7370 },
      { nextLevelXP: 100090, passiveIncome: 11000 },
      { nextLevelXP: 154390, passiveIncome: 16430 },
      { nextLevelXP: 238430, passiveIncome: 24530 },
      { nextLevelXP: 368340, passiveIncome: 36600 },
      { nextLevelXP: 569310, passiveIncome: 54600 },
      { nextLevelXP: 879370, passiveIncome: 81540 },
      { nextLevelXP: 1357090, passiveIncome: 121690 },
      { nextLevelXP: 2093240, passiveIncome: 181570 },
      { nextLevelXP: 3229490, passiveIncome: 270430 }
    ]
  },
  'Liquidity Pool': {
    description: 'Provide liquidity to pools and earn transaction fees. The more liquidity, the higher the reward.',
    icon: require('../../assets/images/mine/inv/liquidity_pool.png'),
    levels: [
      { nextLevelXP: 1100, passiveIncome: 110 },
      { nextLevelXP: 1680, passiveIncome: 170 },
      { nextLevelXP: 2590, passiveIncome: 280 },
      { nextLevelXP: 3980, passiveIncome: 450 },
      { nextLevelXP: 6110, passiveIncome: 710 },
      { nextLevelXP: 9390, passiveIncome: 1130 },
      { nextLevelXP: 14420, passiveIncome: 1800 },
      { nextLevelXP: 22110, passiveIncome: 2870 },
      { nextLevelXP: 33870, passiveIncome: 4570 },
      { nextLevelXP: 51860, passiveIncome: 7270 },
      { nextLevelXP: 79510, passiveIncome: 11570 },
      { nextLevelXP: 122020, passiveIncome: 18410 },
      { nextLevelXP: 187240, passiveIncome: 29390 },
      { nextLevelXP: 287480, passiveIncome: 46540 },
      { nextLevelXP: 441090, passiveIncome: 73910 },
      { nextLevelXP: 676710, passiveIncome: 117510 },
      { nextLevelXP: 1038810, passiveIncome: 187200 },
      { nextLevelXP: 1595520, passiveIncome: 298270 },
      { nextLevelXP: 2450480, passiveIncome: 474850 },
      { nextLevelXP: 3764730, passiveIncome: 755460 }
    ]
  },
  'NFT Marketplace': {
    description: 'Buy and trade NFTs. Gain profits by investing in rare and valuable digital assets.',
    icon: require('../../assets/images/mine/inv/nft_marketplace.png'),
    levels: [
      { nextLevelXP: 900, passiveIncome: 90 },
      { nextLevelXP: 1370, passiveIncome: 140 },
      { nextLevelXP: 2100, passiveIncome: 230 },
      { nextLevelXP: 3220, passiveIncome: 360 },
      { nextLevelXP: 4930, passiveIncome: 580 },
      { nextLevelXP: 7570, passiveIncome: 940 },
      { nextLevelXP: 11570, passiveIncome: 1430 },
      { nextLevelXP: 17720, passiveIncome: 2180 },
      { nextLevelXP: 27320, passiveIncome: 3290 },
      { nextLevelXP: 42130, passiveIncome: 4930 },
      { nextLevelXP: 64900, passiveIncome: 7370 },
      { nextLevelXP: 100090, passiveIncome: 11000 },
      { nextLevelXP: 154390, passiveIncome: 16430 },
      { nextLevelXP: 238430, passiveIncome: 24530 },
      { nextLevelXP: 368340, passiveIncome: 36600 },
      { nextLevelXP: 569310, passiveIncome: 54600 },
      { nextLevelXP: 879370, passiveIncome: 81540 },
      { nextLevelXP: 1357090, passiveIncome: 121690 },
      { nextLevelXP: 2093240, passiveIncome: 181570 },
      { nextLevelXP: 3229490, passiveIncome: 270430 }
    ]
  },
  'Smart Contract Fund': {
    description: 'Invest in funds managed by smart contracts. Returns depend on contract performance.',
    icon: require('../../assets/images/mine/inv/smart_contract_fund.png'),
    levels: [
      { nextLevelXP: 1200, passiveIncome: 120 },
      { nextLevelXP: 1840, passiveIncome: 180 },
      { nextLevelXP: 2850, passiveIncome: 300 },
      { nextLevelXP: 4380, passiveIncome: 480 },
      { nextLevelXP: 6740, passiveIncome: 760 },
      { nextLevelXP: 10350, passiveIncome: 1210 },
      { nextLevelXP: 15890, passiveIncome: 1930 },
      { nextLevelXP: 24430, passiveIncome: 3090 },
      { nextLevelXP: 37560, passiveIncome: 4960 },
      { nextLevelXP: 57760, passiveIncome: 7970 },
      { nextLevelXP: 88870, passiveIncome: 12810 },
      { nextLevelXP: 136720, passiveIncome: 20610 },
      { nextLevelXP: 210480, passiveIncome: 33160 },
      { nextLevelXP: 324020, passiveIncome: 53330 },
      { nextLevelXP: 499310, passiveIncome: 85760 },
      { nextLevelXP: 769610, passiveIncome: 137940 },
      { nextLevelXP: 1186480, passiveIncome: 221840 },
      { nextLevelXP: 1829010, passiveIncome: 356720 },
      { nextLevelXP: 2820820, passiveIncome: 573580 },
      { nextLevelXP: 4353780, passiveIncome: 922370 }
    ]
  },
  'Governance Token': {
    description: 'Hold governance tokens to participate in platform decisions and earn voting rewards.',
    icon: require('../../assets/images/mine/inv/governance_token.png'),
    levels: [
      { nextLevelXP: 1500, passiveIncome: 150 },
      { nextLevelXP: 2300, passiveIncome: 230 },
      { nextLevelXP: 3550, passiveIncome: 360 },
      { nextLevelXP: 5480, passiveIncome: 560 },
      { nextLevelXP: 8460, passiveIncome: 870 },
      { nextLevelXP: 13060, passiveIncome: 1350 },
      { nextLevelXP: 20160, passiveIncome: 2090 },
      { nextLevelXP: 31140, passiveIncome: 3230 },
      { nextLevelXP: 48080, passiveIncome: 4990 },
      { nextLevelXP: 74230, passiveIncome: 7690 },
      { nextLevelXP: 114570, passiveIncome: 11850 },
      { nextLevelXP: 176980, passiveIncome: 18270 },
      { nextLevelXP: 273420, passiveIncome: 28190 },
      { nextLevelXP: 422590, passiveIncome: 43530 },
      { nextLevelXP: 653980, passiveIncome: 67250 },
      { nextLevelXP: 1011860, passiveIncome: 103890 },
      { nextLevelXP: 1564010, passiveIncome: 160540 },
      { nextLevelXP: 2417670, passiveIncome: 248200 },
      { nextLevelXP: 3737850, passiveIncome: 383980 },
      { nextLevelXP: 5777580, passiveIncome: 594160 }
    ]
  },
  'Decentralized Exchange': {
    description: 'Provide liquidity on decentralized exchanges to earn fees from trades.',
    icon: require('../../assets/images/mine/inv/decentralized_exchange.png'),
    levels: [
      { nextLevelXP: 1000, passiveIncome: 100 },
      { nextLevelXP: 1530, passiveIncome: 150 },
      { nextLevelXP: 2350, passiveIncome: 250 },
      { nextLevelXP: 3620, passiveIncome: 400 },
      { nextLevelXP: 5550, passiveIncome: 650 },
      { nextLevelXP: 8520, passiveIncome: 1050 },
      { nextLevelXP: 13080, passiveIncome: 1600 },
      { nextLevelXP: 20080, passiveIncome: 2450 },
      { nextLevelXP: 30860, passiveIncome: 3700 },
      { nextLevelXP: 47350, passiveIncome: 5550 },
      { nextLevelXP: 72690, passiveIncome: 8300 },
      { nextLevelXP: 111570, passiveIncome: 12450 },
      { nextLevelXP: 171320, passiveIncome: 18650 },
      { nextLevelXP: 263030, passiveIncome: 27900 },
      { nextLevelXP: 404130, passiveIncome: 41700 },
      { nextLevelXP: 620510, passiveIncome: 62400 },
      { nextLevelXP: 952990, passiveIncome: 93300 },
      { nextLevelXP: 1463900, passiveIncome: 139500 },
      { nextLevelXP: 2248210, passiveIncome: 208600 },
      { nextLevelXP: 3450450, passiveIncome: 312000 }
    ]
  },
  'Stablecoin Yield': {
    description: 'Earn stable returns by staking stablecoins. A low-risk option for steady income.',
    icon: require('../../assets/images/mine/inv/stablecoin_yield.png'),
    levels: [
      { nextLevelXP: 1600, passiveIncome: 160 },
      { nextLevelXP: 2450, passiveIncome: 240 },
      { nextLevelXP: 3790, passiveIncome: 370 },
      { nextLevelXP: 5850, passiveIncome: 580 },
      { nextLevelXP: 9030, passiveIncome: 910 },
      { nextLevelXP: 13940, passiveIncome: 1410 },
      { nextLevelXP: 21540, passiveIncome: 2180 },
      { nextLevelXP: 33270, passiveIncome: 3370 },
      { nextLevelXP: 51390, passiveIncome: 5210 },
      { nextLevelXP: 79360, passiveIncome: 8050 },
      { nextLevelXP: 122690, passiveIncome: 12450 },
      { nextLevelXP: 189620, passiveIncome: 19240 },
      { nextLevelXP: 293050, passiveIncome: 29750 },
      { nextLevelXP: 452860, passiveIncome: 46010 },
      { nextLevelXP: 699440, passiveIncome: 71230 },
      { nextLevelXP: 1080420, passiveIncome: 110330 },
      { nextLevelXP: 1669450, passiveIncome: 170800 },
      { nextLevelXP: 2579180, passiveIncome: 264490 },
      { nextLevelXP: 3986820, passiveIncome: 409580 },
      { nextLevelXP: 6159340, passiveIncome: 634210 }
    ]
  },
  'NFT Staking': {
    description: 'Stake your NFTs to earn unique tokens or rewards over time.',
    icon: require('../../assets/images/mine/inv/nft_staking.png'),
    levels: [
      { nextLevelXP: 1000, passiveIncome: 100 },
      { nextLevelXP: 1530, passiveIncome: 150 },
      { nextLevelXP: 2350, passiveIncome: 250 },
      { nextLevelXP: 3620, passiveIncome: 400 },
      { nextLevelXP: 5550, passiveIncome: 650 },
      { nextLevelXP: 8520, passiveIncome: 1050 },
      { nextLevelXP: 13080, passiveIncome: 1600 },
      { nextLevelXP: 20080, passiveIncome: 2450 },
      { nextLevelXP: 30860, passiveIncome: 3700 },
      { nextLevelXP: 47350, passiveIncome: 5550 },
      { nextLevelXP: 72690, passiveIncome: 8300 },
      { nextLevelXP: 111570, passiveIncome: 12450 },
      { nextLevelXP: 171320, passiveIncome: 18650 },
      { nextLevelXP: 263030, passiveIncome: 27900 },
      { nextLevelXP: 404130, passiveIncome: 41700 },
      { nextLevelXP: 620510, passiveIncome: 62400 },
      { nextLevelXP: 952990, passiveIncome: 93300 },
      { nextLevelXP: 1463900, passiveIncome: 139500 },
      { nextLevelXP: 2248210, passiveIncome: 208600 },
      { nextLevelXP: 3450450, passiveIncome: 312000 }
    ]
  },
  'Bitcoin (BTC)': {
    description: 'Purchase Bitcoin and hold it as a long-term investment. Benefit from price appreciation.',
    icon: require('../../assets/images/mine/inv/bitcoin(BTC).png'),
    levels: [
      { nextLevelXP: 1687, passiveIncome: 168 },
      { nextLevelXP: 2543, passiveIncome: 253 },
      { nextLevelXP: 3901, passiveIncome: 389 },
      { nextLevelXP: 5847, passiveIncome: 584 },
      { nextLevelXP: 8763, passiveIncome: 876 },
      { nextLevelXP: 13137, passiveIncome: 1313 },
      { nextLevelXP: 19716, passiveIncome: 1971 },
      { nextLevelXP: 29565, passiveIncome: 2956 },
      { nextLevelXP: 44342, passiveIncome: 4434 },
      { nextLevelXP: 66453, passiveIncome: 6645 },
      { nextLevelXP: 99528, passiveIncome: 9953 },
      { nextLevelXP: 149985, passiveIncome: 14998 },
      { nextLevelXP: 224987, passiveIncome: 22499 },
      { nextLevelXP: 337480, passiveIncome: 33748 },
      { nextLevelXP: 506220, passiveIncome: 50622 },
      { nextLevelXP: 759330, passiveIncome: 75933 },
      { nextLevelXP: 1139000, passiveIncome: 113900 },
      { nextLevelXP: 1708500, passiveIncome: 170850 },
      { nextLevelXP: 2562750, passiveIncome: 256275 },
      { nextLevelXP: 3844120, passiveIncome: 384412 }
    ]
  },
  'Ethereum (ETH)': {
    description: 'Buy Ethereum to use or hold. Earn returns from network usage and staking.',
    icon: require('../../assets/images/mine/inv/ethereum(ETH).png'),
    levels: [
      { nextLevelXP: 1243, passiveIncome: 124 },
      { nextLevelXP: 1879, passiveIncome: 188 },
      { nextLevelXP: 2847, passiveIncome: 284 },
      { nextLevelXP: 4307, passiveIncome: 431 },
      { nextLevelXP: 6510, passiveIncome: 651 },
      { nextLevelXP: 9837, passiveIncome: 983 },
      { nextLevelXP: 14755, passiveIncome: 1475 },
      { nextLevelXP: 22135, passiveIncome: 2213 },
      { nextLevelXP: 33202, passiveIncome: 3320 },
      { nextLevelXP: 49803, passiveIncome: 4980 },
      { nextLevelXP: 74704, passiveIncome: 7470 },
      { nextLevelXP: 112056, passiveIncome: 11205 },
      { nextLevelXP: 168084, passiveIncome: 16808 },
      { nextLevelXP: 252126, passiveIncome: 25213 },
      { nextLevelXP: 378189, passiveIncome: 37819 },
      { nextLevelXP: 567283, passiveIncome: 56728 },
      { nextLevelXP: 850924, passiveIncome: 85092 },
      { nextLevelXP: 1276386, passiveIncome: 127639 },
      { nextLevelXP: 1914579, passiveIncome: 191458 },
      { nextLevelXP: 2871869, passiveIncome: 287187 }
    ]
  },
  'Binance Coin (BNB)': {
    description: 'Purchase BNB to benefit from Binance ecosystem rewards and discounts.',
    icon: require('../../assets/images/mine/inv/binance_coin(BNB).png'),
    levels: [
      { nextLevelXP: 1567, passiveIncome: 156 },
      { nextLevelXP: 2365, passiveIncome: 236 },
      { nextLevelXP: 3569, passiveIncome: 357 },
      { nextLevelXP: 5379, passiveIncome: 537 },
      { nextLevelXP: 8100, passiveIncome: 810 },
      { nextLevelXP: 12151, passiveIncome: 1215 },
      { nextLevelXP: 18227, passiveIncome: 1823 },
      { nextLevelXP: 27341, passiveIncome: 2734 },
      { nextLevelXP: 41013, passiveIncome: 4101 },
      { nextLevelXP: 61519, passiveIncome: 6152 },
      { nextLevelXP: 92279, passiveIncome: 9228 },
      { nextLevelXP: 138419, passiveIncome: 13841 },
      { nextLevelXP: 207628, passiveIncome: 20762 },
      { nextLevelXP: 311442, passiveIncome: 31144 },
      { nextLevelXP: 467163, passiveIncome: 46716 },
      { nextLevelXP: 700745, passiveIncome: 70074 },
      { nextLevelXP: 1051117, passiveIncome: 105112 },
      { nextLevelXP: 1576675, passiveIncome: 157668 },
      { nextLevelXP: 2365012, passiveIncome: 236501 },
      { nextLevelXP: 3547518, passiveIncome: 354751 }
    ]
  },
  'Cardano (ADA)': {
    description: 'Buy ADA to participate in the Cardano blockchain and earn staking rewards.',
    icon: require('../../assets/images/mine/inv/cardano(ADA).png'),
    levels: [
      { nextLevelXP: 2157, passiveIncome: 216 },
      { nextLevelXP: 3236, passiveIncome: 324 },
      { nextLevelXP: 4854, passiveIncome: 485 },
      { nextLevelXP: 7282, passiveIncome: 729 },
      { nextLevelXP: 10923, passiveIncome: 1092 },
      { nextLevelXP: 16384, passiveIncome: 1638 },
      { nextLevelXP: 24576, passiveIncome: 2457 },
      { nextLevelXP: 36864, passiveIncome: 3686 },
      { nextLevelXP: 55296, passiveIncome: 5529 },
      { nextLevelXP: 82944, passiveIncome: 8294 },
      { nextLevelXP: 124416, passiveIncome: 12442 },
      { nextLevelXP: 186624, passiveIncome: 18662 },
      { nextLevelXP: 279936, passiveIncome: 27993 },
      { nextLevelXP: 419904, passiveIncome: 41990 },
      { nextLevelXP: 629856, passiveIncome: 62986 },
      { nextLevelXP: 944784, passiveIncome: 94478 },
      { nextLevelXP: 1417176, passiveIncome: 141717 },
      { nextLevelXP: 2125764, passiveIncome: 212576 },
      { nextLevelXP: 3188646, passiveIncome: 318865 },
      { nextLevelXP: 4782969, passiveIncome: 478297 }
    ]
  },
  'Solana (SOL)': {
    description: 'Invest in Solana for fast transactions and staking opportunities.',
    icon: require('../../assets/images/mine/inv/solana(SOL).png'),
    levels: [
      { nextLevelXP: 2543, passiveIncome: 254 },
      { nextLevelXP: 3815, passiveIncome: 381 },
      { nextLevelXP: 5722, passiveIncome: 572 },
      { nextLevelXP: 8582, passiveIncome: 858 },
      { nextLevelXP: 12873, passiveIncome: 1287 },
      { nextLevelXP: 19310, passiveIncome: 1931 },
      { nextLevelXP: 28965, passiveIncome: 2897 },
      { nextLevelXP: 43448, passiveIncome: 4344 },
      { nextLevelXP: 65172, passiveIncome: 6517 },
      { nextLevelXP: 97758, passiveIncome: 9776 },
      { nextLevelXP: 146637, passiveIncome: 14664 },
      { nextLevelXP: 219956, passiveIncome: 21996 },
      { nextLevelXP: 329934, passiveIncome: 32993 },
      { nextLevelXP: 494901, passiveIncome: 49490 },
      { nextLevelXP: 742351, passiveIncome: 74235 },
      { nextLevelXP: 1113527, passiveIncome: 111353 },
      { nextLevelXP: 1670290, passiveIncome: 167029 },
      { nextLevelXP: 2505435, passiveIncome: 250544 },
      { nextLevelXP: 3758153, passiveIncome: 375815 },
      { nextLevelXP: 5637229, passiveIncome: 563723 }
    ]
  },
  'Polkadot (DOT)': {
    description: 'Purchase DOT to participate in Polkadot governance and earn rewards.',
    icon: require('../../assets/images/mine/inv/polkadot(DOT).png'),
    levels: [
      { nextLevelXP: 2768, passiveIncome: 277 },
      { nextLevelXP: 4152, passiveIncome: 415 },
      { nextLevelXP: 6229, passiveIncome: 623 },
      { nextLevelXP: 9343, passiveIncome: 934 },
      { nextLevelXP: 14015, passiveIncome: 1402 },
      { nextLevelXP: 21022, passiveIncome: 2102 },
      { nextLevelXP: 31533, passiveIncome: 3153 },
      { nextLevelXP: 47299, passiveIncome: 4730 },
      { nextLevelXP: 70948, passiveIncome: 7094 },
      { nextLevelXP: 106421, passiveIncome: 10642 },
      { nextLevelXP: 159631, passiveIncome: 15963 },
      { nextLevelXP: 239447, passiveIncome: 23945 },
      { nextLevelXP: 359171, passiveIncome: 35917 },
      { nextLevelXP: 538757, passiveIncome: 53876 },
      { nextLevelXP: 808135, passiveIncome: 80814 },
      { nextLevelXP: 1212202, passiveIncome: 121220 },
      { nextLevelXP: 1818303, passiveIncome: 181830 },
      { nextLevelXP: 2727455, passiveIncome: 272746 },
      { nextLevelXP: 4091182, passiveIncome: 409118 },
      { nextLevelXP: 6136773, passiveIncome: 613677 }
    ]
  },
  'Avalanche (AVAX)': {
    description: 'Buy AVAX to access fast and low-cost decentralized applications.',
    icon: require('../../assets/images/mine/inv/avalanche(AVAX).png'),
    levels: [
      { nextLevelXP: 1587, passiveIncome: 159 },
      { nextLevelXP: 2461, passiveIncome: 245 },
      { nextLevelXP: 3710, passiveIncome: 370 },
      { nextLevelXP: 5605, passiveIncome: 560 },
      { nextLevelXP: 8407, passiveIncome: 841 },
      { nextLevelXP: 12611, passiveIncome: 1260 },
      { nextLevelXP: 18916, passiveIncome: 1891 },
      { nextLevelXP: 28374, passiveIncome: 2837 },
      { nextLevelXP: 42561, passiveIncome: 4256 },
      { nextLevelXP: 63841, passiveIncome: 6384 },
      { nextLevelXP: 95761, passiveIncome: 9576 },
      { nextLevelXP: 143642, passiveIncome: 14364 },
      { nextLevelXP: 215463, passiveIncome: 21546 },
      { nextLevelXP: 323195, passiveIncome: 32319 },
      { nextLevelXP: 484792, passiveIncome: 48479 },
      { nextLevelXP: 727188, passiveIncome: 72719 },
      { nextLevelXP: 1090782, passiveIncome: 109078 },
      { nextLevelXP: 1636173, passiveIncome: 163617 },
      { nextLevelXP: 2454259, passiveIncome: 245426 },
      { nextLevelXP: 3681388, passiveIncome: 368139 }
    ]
  },
  'Chainlink (LINK)': {
    description: 'Invest in LINK to gain from the growth of decentralized oracles.',
    icon: require('../../assets/images/mine/inv/chainlink(LINK).png'),
    levels: [
      { nextLevelXP: 1463, passiveIncome: 146 },
      { nextLevelXP: 2239, passiveIncome: 224 },
      { nextLevelXP: 3426, passiveIncome: 343 },
      { nextLevelXP: 5239, passiveIncome: 524 },
      { nextLevelXP: 8011, passiveIncome: 801 },
      { nextLevelXP: 12271, passiveIncome: 1227 },
      { nextLevelXP: 18406, passiveIncome: 1840 },
      { nextLevelXP: 27609, passiveIncome: 2760 },
      { nextLevelXP: 41413, passiveIncome: 4141 },
      { nextLevelXP: 62120, passiveIncome: 6212 },
      { nextLevelXP: 93180, passiveIncome: 9318 },
      { nextLevelXP: 139770, passiveIncome: 13977 },
      { nextLevelXP: 209655, passiveIncome: 20965 },
      { nextLevelXP: 314483, passiveIncome: 31448 },
      { nextLevelXP: 471724, passiveIncome: 47172 },
      { nextLevelXP: 707586, passiveIncome: 70759 },
      { nextLevelXP: 1061379, passiveIncome: 106138 },
      { nextLevelXP: 1592069, passiveIncome: 159207 },
      { nextLevelXP: 2388104, passiveIncome: 238810 },
      { nextLevelXP: 3582156, passiveIncome: 358216 }
    ]
  },
  'Litecoin (LTC)': {
    description: 'Purchase Litecoin as a fast and low-cost transaction currency.',
    icon: require('../../assets/images/mine/inv/litecoin(LTC).png'),
    levels: [
      { nextLevelXP: 1587, passiveIncome: 158 },
      { nextLevelXP: 2432, passiveIncome: 243 },
      { nextLevelXP: 3731, passiveIncome: 373 },
      { nextLevelXP: 5695, passiveIncome: 570 },
      { nextLevelXP: 8698, passiveIncome: 860 },
      { nextLevelXP: 13047, passiveIncome: 1305 },
      { nextLevelXP: 19570, passiveIncome: 1957 },
      { nextLevelXP: 29354, passiveIncome: 2935 },
      { nextLevelXP: 44031, passiveIncome: 4403 },
      { nextLevelXP: 66047, passiveIncome: 6605 },
      { nextLevelXP: 99071, passiveIncome: 9907 },
      { nextLevelXP: 148606, passiveIncome: 14860 },
      { nextLevelXP: 222909, passiveIncome: 22291 },
      { nextLevelXP: 334363, passiveIncome: 33436 },
      { nextLevelXP: 501544, passiveIncome: 50154 },
      { nextLevelXP: 752316, passiveIncome: 75231 },
      { nextLevelXP: 1128474, passiveIncome: 112847 },
      { nextLevelXP: 1692711, passiveIncome: 169271 },
      { nextLevelXP: 2539067, passiveIncome: 253907 },
      { nextLevelXP: 3808601, passiveIncome: 380860 }
    ]
  },
  'Ripple (XRP)': {
    description: 'Buy XRP to benefit from its use in global payments and remittances.',
    icon: require('../../assets/images/mine/inv/ripple(XRP).png'),
    levels: [
      { nextLevelXP: 1729, passiveIncome: 172 },
      { nextLevelXP: 2648, passiveIncome: 265 },
      { nextLevelXP: 4049, passiveIncome: 404 },
      { nextLevelXP: 6074, passiveIncome: 607 },
      { nextLevelXP: 9111, passiveIncome: 911 },
      { nextLevelXP: 13666, passiveIncome: 1366 },
      { nextLevelXP: 20499, passiveIncome: 2049 },
      { nextLevelXP: 30748, passiveIncome: 3075 },
      { nextLevelXP: 46122, passiveIncome: 4612 },
      { nextLevelXP: 69183, passiveIncome: 6918 },
      { nextLevelXP: 103775, passiveIncome: 10378 },
      { nextLevelXP: 155663, passiveIncome: 15566 },
      { nextLevelXP: 233495, passiveIncome: 23349 },
      { nextLevelXP: 350242, passiveIncome: 35024 },
      { nextLevelXP: 525363, passiveIncome: 52536 },
      { nextLevelXP: 788045, passiveIncome: 78804 },
      { nextLevelXP: 1182067, passiveIncome: 118207 },
      { nextLevelXP: 1773101, passiveIncome: 177310 },
      { nextLevelXP: 2659652, passiveIncome: 265965 },
      { nextLevelXP: 3989478, passiveIncome: 398948 }
    ]
  },
};

const freelanceData = {
  'Work Exchange': {
    description: 'Trade your labor for accommodation. Work in various locations and earn free stays.',
    icon: require('../../assets/images/mine/freel/work_exchange.png'),
    levels: [
      { nextLevelXP: 1187, passiveIncome: 119 },
      { nextLevelXP: 1953, passiveIncome: 192 },
      { nextLevelXP: 2863, passiveIncome: 287 },
      { nextLevelXP: 4212, passiveIncome: 422 },
      { nextLevelXP: 6341, passiveIncome: 635 },
      { nextLevelXP: 9487, passiveIncome: 949 },
      { nextLevelXP: 14220, passiveIncome: 1422 },
      { nextLevelXP: 21467, passiveIncome: 2149 },
      { nextLevelXP: 32099, passiveIncome: 3223 },
      { nextLevelXP: 48345, passiveIncome: 4834 },
      { nextLevelXP: 72551, passiveIncome: 7256 },
      { nextLevelXP: 108425, passiveIncome: 10843 },
      { nextLevelXP: 162638, passiveIncome: 16264 },
      { nextLevelXP: 243957, passiveIncome: 24395 },
      { nextLevelXP: 365935, passiveIncome: 36593 },
      { nextLevelXP: 548903, passiveIncome: 54890 },
      { nextLevelXP: 823354, passiveIncome: 82335 },
      { nextLevelXP: 1235030, passiveIncome: 123503 },
      { nextLevelXP: 1849875, passiveIncome: 184988 },
      { nextLevelXP: 2774812, passiveIncome: 277481 }
    ]
  },
  'Ride-Share Driver': {
    description: 'Drive passengers to their destinations. Earn income by offering ride-sharing services.',
    icon: require('../../assets/images/mine/freel/ride_share_driver.png'),
    levels: [
      { nextLevelXP: 1235, passiveIncome: 122 },
      { nextLevelXP: 1897, passiveIncome: 187 },
      { nextLevelXP: 2789, passiveIncome: 278 },
      { nextLevelXP: 4276, passiveIncome: 427 },
      { nextLevelXP: 6521, passiveIncome: 652 },
      { nextLevelXP: 9654, passiveIncome: 963 },
      { nextLevelXP: 14387, passiveIncome: 1438 },
      { nextLevelXP: 21865, passiveIncome: 2187 },
      { nextLevelXP: 32697, passiveIncome: 3270 },
      { nextLevelXP: 49046, passiveIncome: 4904 },
      { nextLevelXP: 73392, passiveIncome: 7339 },
      { nextLevelXP: 109438, passiveIncome: 10944 },
      { nextLevelXP: 164157, passiveIncome: 16416 },
      { nextLevelXP: 246186, passiveIncome: 24618 },
      { nextLevelXP: 369279, passiveIncome: 36928 },
      { nextLevelXP: 554374, passiveIncome: 55437 },
      { nextLevelXP: 832058, passiveIncome: 83206 },
      { nextLevelXP: 1248087, passiveIncome: 124809 },
      { nextLevelXP: 1872131, passiveIncome: 187213 },
      { nextLevelXP: 2808197, passiveIncome: 280820 }
    ]
  },
  'Food Courier': {
    description: 'Deliver food to customers. Earn money by completing delivery orders on time.',
    icon: require('../../assets/images/mine/freel/food_courier.png'),
    levels: [
      { nextLevelXP: 1158, passiveIncome: 115 },
      { nextLevelXP: 1872, passiveIncome: 182 },
      { nextLevelXP: 2718, passiveIncome: 271 },
      { nextLevelXP: 4158, passiveIncome: 416 },
      { nextLevelXP: 6342, passiveIncome: 631 },
      { nextLevelXP: 9532, passiveIncome: 951 },
      { nextLevelXP: 14315, passiveIncome: 1431 },
      { nextLevelXP: 21598, passiveIncome: 2159 },
      { nextLevelXP: 32487, passiveIncome: 3249 },
      { nextLevelXP: 48731, passiveIncome: 4873 },
      { nextLevelXP: 73097, passiveIncome: 7310 },
      { nextLevelXP: 108564, passiveIncome: 10856 },
      { nextLevelXP: 162847, passiveIncome: 16285 },
      { nextLevelXP: 244270, passiveIncome: 24427 },
      { nextLevelXP: 366405, passiveIncome: 36641 },
      { nextLevelXP: 549615, passiveIncome: 54962 },
      { nextLevelXP: 824423, passiveIncome: 82442 },
      { nextLevelXP: 1236634, passiveIncome: 123663 },
      { nextLevelXP: 1851448, passiveIncome: 185145 },
      { nextLevelXP: 2777172, passiveIncome: 277717 }
    ]
  },
  'Online Tutor': {
    description: 'Teach students online. Share your knowledge and earn tutoring fees.',
    icon: require('../../assets/images/mine/freel/online_tutor.png'),
    levels: [
      { nextLevelXP: 1201, passiveIncome: 118 },
      { nextLevelXP: 1842, passiveIncome: 193 },
      { nextLevelXP: 2735, passiveIncome: 285 },
      { nextLevelXP: 4230, passiveIncome: 438 },
      { nextLevelXP: 6485, passiveIncome: 678 },
      { nextLevelXP: 9714, passiveIncome: 998 },
      { nextLevelXP: 14523, passiveIncome: 1512 },
      { nextLevelXP: 21874, passiveIncome: 2312 },
      { nextLevelXP: 32678, passiveIncome: 3474 },
      { nextLevelXP: 49215, passiveIncome: 5085 },
      { nextLevelXP: 73627, passiveIncome: 7620 },
      { nextLevelXP: 109154, passiveIncome: 11281 },
      { nextLevelXP: 164123, passiveIncome: 17432 },
      { nextLevelXP: 247083, passiveIncome: 26024 },
      { nextLevelXP: 370625, passiveIncome: 39036 },
      { nextLevelXP: 556059, passiveIncome: 58286 },
      { nextLevelXP: 835518, passiveIncome: 87429 },
      { nextLevelXP: 1251245, passiveIncome: 131143 },
      { nextLevelXP: 1878419, passiveIncome: 196859 },
      { nextLevelXP: 2818128, passiveIncome: 295289 }
    ]
  },
  'Freelance Writer': {
    description: 'Write articles and content for clients. Get paid for each submission.',
    icon: require('../../assets/images/mine/freel/freelance_writer.png'),
    levels: [
      { nextLevelXP: 1173, passiveIncome: 120 },
      { nextLevelXP: 1892, passiveIncome: 192 },
      { nextLevelXP: 2702, passiveIncome: 271 },
      { nextLevelXP: 4223, passiveIncome: 425 },
      { nextLevelXP: 6358, passiveIncome: 648 },
      { nextLevelXP: 9621, passiveIncome: 971 },
      { nextLevelXP: 14239, passiveIncome: 1425 },
      { nextLevelXP: 21702, passiveIncome: 2156 },
      { nextLevelXP: 32398, passiveIncome: 3247 },
      { nextLevelXP: 48923, passiveIncome: 4863 },
      { nextLevelXP: 73542, passiveIncome: 7369 },
      { nextLevelXP: 108567, passiveIncome: 10885 },
      { nextLevelXP: 162785, passiveIncome: 16320 },
      { nextLevelXP: 243482, passiveIncome: 24338 },
      { nextLevelXP: 365243, passiveIncome: 36654 },
      { nextLevelXP: 548239, passiveIncome: 54882 },
      { nextLevelXP: 822358, passiveIncome: 82318 },
      { nextLevelXP: 1233581, passiveIncome: 123422 },
      { nextLevelXP: 1848765, passiveIncome: 184978 },
      { nextLevelXP: 2773284, passiveIncome: 277329 }
    ]
  },
  'Freelance Translator': {
    description: 'Provide translation services. Earn by translating documents and content.',
    icon: require('../../assets/images/mine/freel/freelance_translator.png'),
    levels: [
      { nextLevelXP: 1214, passiveIncome: 124 },
      { nextLevelXP: 1899, passiveIncome: 194 },
      { nextLevelXP: 2746, passiveIncome: 283 },
      { nextLevelXP: 4239, passiveIncome: 428 },
      { nextLevelXP: 6367, passiveIncome: 637 },
      { nextLevelXP: 9654, passiveIncome: 973 },
      { nextLevelXP: 14372, passiveIncome: 1437 },
      { nextLevelXP: 21945, passiveIncome: 2193 },
      { nextLevelXP: 32729, passiveIncome: 3276 },
      { nextLevelXP: 49134, passiveIncome: 4913 },
      { nextLevelXP: 73847, passiveIncome: 7385 },
      { nextLevelXP: 109274, passiveIncome: 10927 },
      { nextLevelXP: 164012, passiveIncome: 16401 },
      { nextLevelXP: 246319, passiveIncome: 24632 },
      { nextLevelXP: 369478, passiveIncome: 36948 },
      { nextLevelXP: 554216, passiveIncome: 55422 },
      { nextLevelXP: 832122, passiveIncome: 83212 },
      { nextLevelXP: 1247568, passiveIncome: 124756 },
      { nextLevelXP: 1871352, passiveIncome: 187135 },
      { nextLevelXP: 2807028, passiveIncome: 280703 }
    ]
  },
  'Freelance Photographer': {
    description: 'Capture moments for clients. Get paid for your photography skills.',
    icon: require('../../assets/images/mine/freel/freelance_photographer.png'),
    levels: [
      { nextLevelXP: 1205, passiveIncome: 121 },
      { nextLevelXP: 1876, passiveIncome: 187 },
      { nextLevelXP: 2691, passiveIncome: 269 },
      { nextLevelXP: 4215, passiveIncome: 421 },
      { nextLevelXP: 6319, passiveIncome: 631 },
      { nextLevelXP: 9514, passiveIncome: 951 },
      { nextLevelXP: 14274, passiveIncome: 1427 },
      { nextLevelXP: 21837, passiveIncome: 2184 },
      { nextLevelXP: 32613, passiveIncome: 3261 },
      { nextLevelXP: 48920, passiveIncome: 4892 },
      { nextLevelXP: 73542, passiveIncome: 7354 },
      { nextLevelXP: 108639, passiveIncome: 10864 },
      { nextLevelXP: 162849, passiveIncome: 16285 },
      { nextLevelXP: 244394, passiveIncome: 24439 },
      { nextLevelXP: 366297, passiveIncome: 36629 },
      { nextLevelXP: 549321, passiveIncome: 54932 },
      { nextLevelXP: 824547, passiveIncome: 82455 },
      { nextLevelXP: 1237677, passiveIncome: 123768 },
      { nextLevelXP: 1852629, passiveIncome: 185263 },
      { nextLevelXP: 2778943, passiveIncome: 277894 }
    ]
  },
  'Graphic Designer': {
    description: 'Design logos, banners, and more. Earn income by completing design projects.',
    icon: require('../../assets/images/mine/freel/graphic_designer.png'),
    levels: [
      { nextLevelXP: 1198, passiveIncome: 120 },
      { nextLevelXP: 1889, passiveIncome: 189 },
      { nextLevelXP: 2734, passiveIncome: 273 },
      { nextLevelXP: 4234, passiveIncome: 423 },
      { nextLevelXP: 6453, passiveIncome: 645 },
      { nextLevelXP: 9673, passiveIncome: 967 },
      { nextLevelXP: 14392, passiveIncome: 1439 },
      { nextLevelXP: 21978, passiveIncome: 2197 },
      { nextLevelXP: 32764, passiveIncome: 3276 },
      { nextLevelXP: 49268, passiveIncome: 4926 },
      { nextLevelXP: 73902, passiveIncome: 7390 },
      { nextLevelXP: 109347, passiveIncome: 10935 },
      { nextLevelXP: 164020, passiveIncome: 16402 },
      { nextLevelXP: 246329, passiveIncome: 24633 },
      { nextLevelXP: 369493, passiveIncome: 36949 },
      { nextLevelXP: 554240, passiveIncome: 55424 },
      { nextLevelXP: 832160, passiveIncome: 83216 },
      { nextLevelXP: 1247740, passiveIncome: 124774 },
      { nextLevelXP: 1871610, passiveIncome: 187161 },
      { nextLevelXP: 2807415, passiveIncome: 280742 }
    ]
  },
  'Web Developer': {
    description: 'Build websites and applications. Earn money by coding and launching projects.',
    icon: require('../../assets/images/mine/freel/web_developer.png'),
    levels: [
      { nextLevelXP: 1256, passiveIncome: 128 },
      { nextLevelXP: 1917, passiveIncome: 192 },
      { nextLevelXP: 2786, passiveIncome: 281 },
      { nextLevelXP: 4306, passiveIncome: 433 },
      { nextLevelXP: 6576, passiveIncome: 658 },
      { nextLevelXP: 9841, passiveIncome: 984 },
      { nextLevelXP: 14612, passiveIncome: 1461 },
      { nextLevelXP: 22297, passiveIncome: 2230 },
      { nextLevelXP: 33114, passiveIncome: 3312 },
      { nextLevelXP: 49890, passiveIncome: 4989 },
      { nextLevelXP: 74829, passiveIncome: 7482 },
      { nextLevelXP: 110353, passiveIncome: 11035 },
      { nextLevelXP: 165529, passiveIncome: 16553 },
      { nextLevelXP: 248294, passiveIncome: 24829 },
      { nextLevelXP: 372441, passiveIncome: 37244 },
      { nextLevelXP: 558661, passiveIncome: 55866 },
      { nextLevelXP: 837991, passiveIncome: 83799 },
      { nextLevelXP: 1256986, passiveIncome: 125698 },
      { nextLevelXP: 1885479, passiveIncome: 188548 },
      { nextLevelXP: 2828219, passiveIncome: 282821 }
    ]
  },
  'Video Editor': {
    description: 'Edit and create videos for clients. Earn by producing polished video content.',
    icon: require('../../assets/images/mine/freel/video_editor.png'),
    levels: [
      { nextLevelXP: 1237, passiveIncome: 122 },
      { nextLevelXP: 1903, passiveIncome: 190 },
      { nextLevelXP: 2763, passiveIncome: 276 },
      { nextLevelXP: 4299, passiveIncome: 430 },
      { nextLevelXP: 6539, passiveIncome: 653 },
      { nextLevelXP: 9798, passiveIncome: 980 },
      { nextLevelXP: 14546, passiveIncome: 1455 },
      { nextLevelXP: 22106, passiveIncome: 2211 },
      { nextLevelXP: 32987, passiveIncome: 3299 },
      { nextLevelXP: 48880, passiveIncome: 4888 },
      { nextLevelXP: 73320, passiveIncome: 7332 },
      { nextLevelXP: 108928, passiveIncome: 10893 },
      { nextLevelXP: 163392, passiveIncome: 16339 },
      { nextLevelXP: 245088, passiveIncome: 24508 },
      { nextLevelXP: 367632, passiveIncome: 36763 },
      { nextLevelXP: 551448, passiveIncome: 55145 },
      { nextLevelXP: 827172, passiveIncome: 82717 },
      { nextLevelXP: 1240758, passiveIncome: 124076 },
      { nextLevelXP: 1861137, passiveIncome: 186114 },
      { nextLevelXP: 2791706, passiveIncome: 279171 }
    ]
  },
  'Social Media Manager': {
    description: 'Manage social media accounts for businesses. Earn income by boosting online presence.',
    icon: require('../../assets/images/mine/freel/social_media_manager.png'),
    levels: [
      { nextLevelXP: 2184, passiveIncome: 124 },
      { nextLevelXP: 3762, passiveIncome: 189 },
      { nextLevelXP: 5423, passiveIncome: 271 },
      { nextLevelXP: 8462, passiveIncome: 425 },
      { nextLevelXP: 12697, passiveIncome: 639 },
      { nextLevelXP: 18512, passiveIncome: 968 },
      { nextLevelXP: 27194, passiveIncome: 1448 },
      { nextLevelXP: 39372, passiveIncome: 2209 },
      { nextLevelXP: 57876, passiveIncome: 3304 },
      { nextLevelXP: 85326, passiveIncome: 4956 },
      { nextLevelXP: 125792, passiveIncome: 7434 },
      { nextLevelXP: 185473, passiveIncome: 11014 },
      { nextLevelXP: 273128, passiveIncome: 16521 },
      { nextLevelXP: 402312, passiveIncome: 24819 },
      { nextLevelXP: 592413, passiveIncome: 37228 },
      { nextLevelXP: 872981, passiveIncome: 55843 },
      { nextLevelXP: 1285472, passiveIncome: 83764 },
      { nextLevelXP: 1891218, passiveIncome: 125645 },
      { nextLevelXP: 2779739, passiveIncome: 188558 },
      { nextLevelXP: 4083561, passiveIncome: 282838 }
    ]
  },
  'Craft Maker': {
    description: 'Create handmade crafts. Sell your unique creations to earn profit.',
    icon: require('../../assets/images/mine/freel/craft_maker.png'),
    levels: [
      { nextLevelXP: 2342, passiveIncome: 119 },
      { nextLevelXP: 4020, passiveIncome: 187 },
      { nextLevelXP: 5896, passiveIncome: 273 },
      { nextLevelXP: 9213, passiveIncome: 421 },
      { nextLevelXP: 13814, passiveIncome: 631 },
      { nextLevelXP: 20548, passiveIncome: 961 },
      { nextLevelXP: 30780, passiveIncome: 1423 },
      { nextLevelXP: 46215, passiveIncome: 2189 },
      { nextLevelXP: 69874, passiveIncome: 3264 },
      { nextLevelXP: 105145, passiveIncome: 4894 },
      { nextLevelXP: 158212, passiveIncome: 7341 },
      { nextLevelXP: 238098, passiveIncome: 10906 },
      { nextLevelXP: 357147, passiveIncome: 16403 },
      { nextLevelXP: 535721, passiveIncome: 24604 },
      { nextLevelXP: 803582, passiveIncome: 36906 },
      { nextLevelXP: 1205373, passiveIncome: 55415 },
      { nextLevelXP: 1807830, passiveIncome: 83122 },
      { nextLevelXP: 2711745, passiveIncome: 124764 },
      { nextLevelXP: 4067618, passiveIncome: 187146 },
      { nextLevelXP: 6103992, passiveIncome: 280718 }
    ]
  },
};

const supplyStationData = {
  'Camping Gear': {
    description: 'A set of camping essentials including a tent, sleeping bag, and portable stove.',
    icon: require('../../assets/images/mine/supp/camping_gear.png'),
    levels: [
      { nextLevelXP: 1120, passiveIncome: 115 },
      { nextLevelXP: 1684, passiveIncome: 172 },
      { nextLevelXP: 2458, passiveIncome: 252 },
      { nextLevelXP: 3585, passiveIncome: 367 },
      { nextLevelXP: 5189, passiveIncome: 532 },
      { nextLevelXP: 7414, passiveIncome: 751 },
      { nextLevelXP: 10595, passiveIncome: 1075 },
      { nextLevelXP: 15140, passiveIncome: 1530 },
      { nextLevelXP: 21640, passiveIncome: 2187 },
      { nextLevelXP: 30888, passiveIncome: 3125 },
      { nextLevelXP: 43948, passiveIncome: 4469 },
      { nextLevelXP: 62214, passiveIncome: 6416 },
      { nextLevelXP: 88087, passiveIncome: 9213 },
      { nextLevelXP: 124161, passiveIncome: 13235 },
      { nextLevelXP: 174683, passiveIncome: 19013 },
      { nextLevelXP: 245823, passiveIncome: 27315 },
      { nextLevelXP: 345631, passiveIncome: 39159 },
      { nextLevelXP: 486712, passiveIncome: 56142 },
      { nextLevelXP: 685620, passiveIncome: 80318 },
      { nextLevelXP: 966772, passiveIncome: 114605 }
    ]
  },
  'Navigation Tools': {
    description: 'Includes a compass, map, and GPS device. Helps you stay on the right path.',
    icon: require('../../assets/images/mine/supp/navigation_tools.png'),
    levels: [
      { nextLevelXP: 1153, passiveIncome: 119 },
      { nextLevelXP: 1735, passiveIncome: 178 },
      { nextLevelXP: 2531, passiveIncome: 260 },
      { nextLevelXP: 3694, passiveIncome: 380 },
      { nextLevelXP: 5351, passiveIncome: 553 },
      { nextLevelXP: 7750, passiveIncome: 801 },
      { nextLevelXP: 11220, passiveIncome: 1160 },
      { nextLevelXP: 16235, passiveIncome: 1680 },
      { nextLevelXP: 23504, passiveIncome: 2434 },
      { nextLevelXP: 34048, passiveIncome: 3526 },
      { nextLevelXP: 49304, passiveIncome: 5108 },
      { nextLevelXP: 72181, passiveIncome: 7400 },
      { nextLevelXP: 105585, passiveIncome: 10730 },
      { nextLevelXP: 154448, passiveIncome: 15560 },
      { nextLevelXP: 226015, passiveIncome: 22575 },
      { nextLevelXP: 330606, passiveIncome: 32751 },
      { nextLevelXP: 484920, passiveIncome: 47558 },
      { nextLevelXP: 711134, passiveIncome: 68944 },
      { nextLevelXP: 1043286, passiveIncome: 99881 },
      { nextLevelXP: 1530801, passiveIncome: 144737 }
    ]
  },
  'Climbing Equipment': {
    description: 'Ropes, harnesses, and carabiners for safe climbing adventures.',
    icon: require('../../assets/images/mine/supp/climbing_equipment.png'),
    levels: [
      { nextLevelXP: 1283, passiveIncome: 130 },
      { nextLevelXP: 2000, passiveIncome: 205 },
      { nextLevelXP: 3118, passiveIncome: 323 },
      { nextLevelXP: 4750, passiveIncome: 487 },
      { nextLevelXP: 7240, passiveIncome: 734 },
      { nextLevelXP: 11047, passiveIncome: 1106 },
      { nextLevelXP: 16872, passiveIncome: 1668 },
      { nextLevelXP: 25741, passiveIncome: 2513 },
      { nextLevelXP: 39305, passiveIncome: 3786 },
      { nextLevelXP: 60000, passiveIncome: 5704 },
      { nextLevelXP: 91552, passiveIncome: 8592 },
      { nextLevelXP: 139819, passiveIncome: 12942 },
      { nextLevelXP: 213483, passiveIncome: 19489 },
      { nextLevelXP: 325801, passiveIncome: 29266 },
      { nextLevelXP: 497471, passiveIncome: 43944 },
      { nextLevelXP: 759585, passiveIncome: 65945 },
      { nextLevelXP: 1160179, passiveIncome: 98946 },
      { nextLevelXP: 1772321, passiveIncome: 148572 },
      { nextLevelXP: 2707239, passiveIncome: 222980 },
      { nextLevelXP: 4131671, passiveIncome: 334670 }
    ]
  },
  'Fishing Kit': {
    description: 'A compact kit with fishing rod, bait, and hooks. Perfect for catching food.',
    icon: require('../../assets/images/mine/supp/fishing_kit.png'),
    levels: [
      { nextLevelXP: 1297, passiveIncome: 126 },
      { nextLevelXP: 2037, passiveIncome: 199 },
      { nextLevelXP: 3194, passiveIncome: 313 },
      { nextLevelXP: 5013, passiveIncome: 492 },
      { nextLevelXP: 7867, passiveIncome: 774 },
      { nextLevelXP: 12358, passiveIncome: 1210 },
      { nextLevelXP: 19403, passiveIncome: 1870 },
      { nextLevelXP: 30418, passiveIncome: 2891 },
      { nextLevelXP: 47669, passiveIncome: 4467 },
      { nextLevelXP: 74696, passiveIncome: 6904 },
      { nextLevelXP: 117098, passiveIncome: 10674 },
      { nextLevelXP: 183504, passiveIncome: 16518 },
      { nextLevelXP: 287374, passiveIncome: 25566 },
      { nextLevelXP: 450278, passiveIncome: 39564 },
      { nextLevelXP: 705581, passiveIncome: 61219 },
      { nextLevelXP: 1105860, passiveIncome: 94638 },
      { nextLevelXP: 1731893, passiveIncome: 146272 },
      { nextLevelXP: 2712379, passiveIncome: 226009 },
      { nextLevelXP: 4246825, passiveIncome: 348847 },
      { nextLevelXP: 6648716, passiveIncome: 538443 }
    ]
  },
  'Travel Medicine': {
    description: 'A collection of essential medicines for common travel illnesses.',
    icon: require('../../assets/images/mine/supp/travel_medicine.png'),
    levels: [
      { nextLevelXP: 1419, passiveIncome: 140 },
      { nextLevelXP: 2223, passiveIncome: 223 },
      { nextLevelXP: 3485, passiveIncome: 352 },
      { nextLevelXP: 5462, passiveIncome: 556 },
      { nextLevelXP: 8557, passiveIncome: 878 },
      { nextLevelXP: 13418, passiveIncome: 1385 },
      { nextLevelXP: 21028, passiveIncome: 2152 },
      { nextLevelXP: 32960, passiveIncome: 3343 },
      { nextLevelXP: 51671, passiveIncome: 5148 },
      { nextLevelXP: 81019, passiveIncome: 7929 },
      { nextLevelXP: 127064, passiveIncome: 12215 },
      { nextLevelXP: 199239, passiveIncome: 18801 },
      { nextLevelXP: 312528, passiveIncome: 28831 },
      { nextLevelXP: 490276, passiveIncome: 44211 },
      { nextLevelXP: 768981, passiveIncome: 67824 },
      { nextLevelXP: 1206839, passiveIncome: 103996 },
      { nextLevelXP: 1893278, passiveIncome: 159505 },
      { nextLevelXP: 2969532, passiveIncome: 244720 },
      { nextLevelXP: 4654602, passiveIncome: 375735 },
      { nextLevelXP: 7288781, passiveIncome: 576710 }
    ]
  },
  'Portable Shelter': {
    description: 'A lightweight, portable shelter for emergency situations.',
    icon: require('../../assets/images/mine/supp/portable_shelter.png'),
    levels: [
      { nextLevelXP: 1438, passiveIncome: 135 },
      { nextLevelXP: 2274, passiveIncome: 219 },
      { nextLevelXP: 3565, passiveIncome: 343 },
      { nextLevelXP: 5587, passiveIncome: 541 },
      { nextLevelXP: 8754, passiveIncome: 853 },
      { nextLevelXP: 13711, passiveIncome: 1348 },
      { nextLevelXP: 21471, passiveIncome: 2130 },
      { nextLevelXP: 33624, passiveIncome: 3366 },
      { nextLevelXP: 52658, passiveIncome: 5321 },
      { nextLevelXP: 82458, passiveIncome: 8412 },
      { nextLevelXP: 129113, passiveIncome: 13301 },
      { nextLevelXP: 202142, passiveIncome: 21034 },
      { nextLevelXP: 316704, passiveIncome: 33199 },
      { nextLevelXP: 496038, passiveIncome: 52399 },
      { nextLevelXP: 777013, passiveIncome: 82612 },
      { nextLevelXP: 1216369, passiveIncome: 130251 },
      { nextLevelXP: 1904807, passiveIncome: 205327 },
      { nextLevelXP: 2981998, passiveIncome: 323665 },
      { nextLevelXP: 4666824, passiveIncome: 510418 },
      { nextLevelXP: 7307679, passiveIncome: 804243 }
    ]
  },
  'Cooking Set': {
    description: 'Includes pots, pans, and utensils for preparing meals outdoors.',
    icon: require('../../assets/images/mine/supp/cooking_set.png'),
    levels: [
      { nextLevelXP: 1598, passiveIncome: 143 },
      { nextLevelXP: 2588, passiveIncome: 231 },
      { nextLevelXP: 4192, passiveIncome: 372 },
      { nextLevelXP: 6788, passiveIncome: 599 },
      { nextLevelXP: 10996, passiveIncome: 965 },
      { nextLevelXP: 17820, passiveIncome: 1554 },
      { nextLevelXP: 28905, passiveIncome: 2503 },
      { nextLevelXP: 46909, passiveIncome: 4032 },
      { nextLevelXP: 76161, passiveIncome: 6493 },
      { nextLevelXP: 123593, passiveIncome: 10467 },
      { nextLevelXP: 200646, passiveIncome: 16872 },
      { nextLevelXP: 325696, passiveIncome: 27213 },
      { nextLevelXP: 528818, passiveIncome: 43805 },
      { nextLevelXP: 858410, passiveIncome: 70524 },
      { nextLevelXP: 1392384, passiveIncome: 113541 },
      { nextLevelXP: 2258056, passiveIncome: 182776 },
      { nextLevelXP: 3661796, passiveIncome: 294192 },
      { nextLevelXP: 5934843, passiveIncome: 473547 },
      { nextLevelXP: 9616684, passiveIncome: 762574 },
      { nextLevelXP: 15590042, passiveIncome: 1228179 }
    ]
  },
  'Water Purification': {
    description: 'A portable water filter or purification tablets for safe drinking water.',
    icon: require('../../assets/images/mine/supp/water_purification.png'),
    levels: [
      { nextLevelXP: 1623, passiveIncome: 147 },
      { nextLevelXP: 2629, passiveIncome: 238 },
      { nextLevelXP: 4268, passiveIncome: 383 },
      { nextLevelXP: 6900, passiveIncome: 617 },
      { nextLevelXP: 11170, passiveIncome: 994 },
      { nextLevelXP: 18089, passiveIncome: 1602 },
      { nextLevelXP: 29292, passiveIncome: 2578 },
      { nextLevelXP: 47435, passiveIncome: 4147 },
      { nextLevelXP: 76851, passiveIncome: 6671 },
      { nextLevelXP: 124542, passiveIncome: 10737 },
      { nextLevelXP: 201897, passiveIncome: 17273 },
      { nextLevelXP: 327252, passiveIncome: 27791 },
      { nextLevelXP: 530170, passiveIncome: 44726 },
      { nextLevelXP: 859092, passiveIncome: 71981 },
      { nextLevelXP: 1392988, passiveIncome: 115866 },
      { nextLevelXP: 2260029, passiveIncome: 186576 },
      { nextLevelXP: 3664158, passiveIncome: 300661 },
      { nextLevelXP: 5939446, passiveIncome: 484171 },
      { nextLevelXP: 9624624, passiveIncome: 779938 },
      { nextLevelXP: 15603428, passiveIncome: 1256899 }
    ]
  },
  'Repair Kit': {
    description: 'Tools and materials for fixing gear and equipment on the go.',
    icon: require('../../assets/images/mine/supp/repair_kit.png'),
    levels: [
      { nextLevelXP: 1110, passiveIncome: 110 },
      { nextLevelXP: 1650, passiveIncome: 165 },
      { nextLevelXP: 2370, passiveIncome: 240 },
      { nextLevelXP: 3405, passiveIncome: 346 },
      { nextLevelXP: 4890, passiveIncome: 500 },
      { nextLevelXP: 7020, passiveIncome: 715 },
      { nextLevelXP: 10070, passiveIncome: 1000 },
      { nextLevelXP: 14450, passiveIncome: 1430 },
      { nextLevelXP: 20700, passiveIncome: 2000 },
      { nextLevelXP: 29690, passiveIncome: 2860 },
      { nextLevelXP: 42550, passiveIncome: 4100 },
      { nextLevelXP: 61000, passiveIncome: 5880 },
      { nextLevelXP: 87500, passiveIncome: 8460 },
      { nextLevelXP: 125500, passiveIncome: 12150 },
      { nextLevelXP: 180000, passiveIncome: 17460 },
      { nextLevelXP: 258000, passiveIncome: 24990 },
      { nextLevelXP: 369000, passiveIncome: 35670 },
      { nextLevelXP: 527000, passiveIncome: 50490 },
      { nextLevelXP: 753000, passiveIncome: 71490 },
      { nextLevelXP: 1075000, passiveIncome: 101000 }
    ]
  },
  'Energy Bars': {
    description: 'High-calorie snacks that provide a quick energy boost.',
    icon: require('../../assets/images/mine/supp/energy_bars.png'),
    levels: [
      { nextLevelXP: 1090, passiveIncome: 105 },
      { nextLevelXP: 1620, passiveIncome: 160 },
      { nextLevelXP: 2340, passiveIncome: 230 },
      { nextLevelXP: 3345, passiveIncome: 340 },
      { nextLevelXP: 4780, passiveIncome: 490 },
      { nextLevelXP: 6860, passiveIncome: 705 },
      { nextLevelXP: 9820, passiveIncome: 980 },
      { nextLevelXP: 14060, passiveIncome: 1415 },
      { nextLevelXP: 19930, passiveIncome: 1995 },
      { nextLevelXP: 28630, passiveIncome: 2860 },
      { nextLevelXP: 41410, passiveIncome: 4135 },
      { nextLevelXP: 59880, passiveIncome: 5985 },
      { nextLevelXP: 86620, passiveIncome: 8600 },
      { nextLevelXP: 125020, passiveIncome: 12105 },
      { nextLevelXP: 181520, passiveIncome: 17565 },
      { nextLevelXP: 263210, passiveIncome: 25445 },
      { nextLevelXP: 381400, passiveIncome: 36865 },
      { nextLevelXP: 552220, passiveIncome: 53370 },
      { nextLevelXP: 799720, passiveIncome: 77280 },
      { nextLevelXP: 1159620, passiveIncome: 111980 }
    ]
  },
  'Solar Charger': {
    description: 'A portable solar panel for charging devices.',
    icon: require('../../assets/images/mine/supp/solar_charger.png'),
    levels: [
      { nextLevelXP: 1150, passiveIncome: 115 },
      { nextLevelXP: 1710, passiveIncome: 170 },
      { nextLevelXP: 2520, passiveIncome: 250 },
      { nextLevelXP: 3610, passiveIncome: 360 },
      { nextLevelXP: 5150, passiveIncome: 515 },
      { nextLevelXP: 7350, passiveIncome: 735 },
      { nextLevelXP: 10470, passiveIncome: 1050 },
      { nextLevelXP: 14900, passiveIncome: 1490 },
      { nextLevelXP: 21200, passiveIncome: 2120 },
      { nextLevelXP: 30180, passiveIncome: 3020 },
      { nextLevelXP: 43400, passiveIncome: 4340 },
      { nextLevelXP: 62460, passiveIncome: 6240 },
      { nextLevelXP: 89570, passiveIncome: 8950 },
      { nextLevelXP: 128540, passiveIncome: 12850 },
      { nextLevelXP: 184400, passiveIncome: 18460 },
      { nextLevelXP: 264100, passiveIncome: 26380 },
      { nextLevelXP: 378200, passiveIncome: 37780 },
      { nextLevelXP: 541280, passiveIncome: 54100 },
      { nextLevelXP: 774640, passiveIncome: 77560 },
      { nextLevelXP: 1108300, passiveIncome: 111540 }
    ]
  },
  'Emergency Beacon': {
    description: 'A device that sends a distress signal in emergencies.',
    icon: require('../../assets/images/mine/supp/emergency_beacon.png'),
    levels: [
      { nextLevelXP: 1130, passiveIncome: 110 },
      { nextLevelXP: 1690, passiveIncome: 160 },
      { nextLevelXP: 2490, passiveIncome: 240 },
      { nextLevelXP: 3510, passiveIncome: 350 },
      { nextLevelXP: 5050, passiveIncome: 500 },
      { nextLevelXP: 7250, passiveIncome: 720 },
      { nextLevelXP: 10280, passiveIncome: 1040 },
      { nextLevelXP: 14700, passiveIncome: 1460 },
      { nextLevelXP: 20900, passiveIncome: 2080 },
      { nextLevelXP: 29580, passiveIncome: 2960 },
      { nextLevelXP: 42520, passiveIncome: 4250 },
      { nextLevelXP: 61140, passiveIncome: 6120 },
      { nextLevelXP: 87520, passiveIncome: 8740 },
      { nextLevelXP: 125280, passiveIncome: 12500 },
      { nextLevelXP: 180960, passiveIncome: 18060 },
      { nextLevelXP: 259000, passiveIncome: 25500 },
      { nextLevelXP: 371200, passiveIncome: 36540 },
      { nextLevelXP: 532000, passiveIncome: 52000 },
      { nextLevelXP: 763400, passiveIncome: 74580 },
      { nextLevelXP: 1092400, passiveIncome: 106740 }
    ]
  },
  'Multi-Tool': {
    description: 'A compact tool with various functions like cutting, screwing, and opening.',
    icon: require('../../assets/images/mine/supp/multi_tool.png'),
    levels: [
      { nextLevelXP: 1285, passiveIncome: 125 },
      { nextLevelXP: 2020, passiveIncome: 195 },
      { nextLevelXP: 3170, passiveIncome: 305 },
      { nextLevelXP: 4950, passiveIncome: 475 },
      { nextLevelXP: 7720, passiveIncome: 740 },
      { nextLevelXP: 11970, passiveIncome: 1145 },
      { nextLevelXP: 18570, passiveIncome: 1780 },
      { nextLevelXP: 28820, passiveIncome: 2750 },
      { nextLevelXP: 44720, passiveIncome: 4260 },
      { nextLevelXP: 69430, passiveIncome: 6630 },
      { nextLevelXP: 107840, passiveIncome: 10300 },
      { nextLevelXP: 167470, passiveIncome: 15990 },
      { nextLevelXP: 259960, passiveIncome: 24820 },
      { nextLevelXP: 403170, passiveIncome: 38500 },
      { nextLevelXP: 624820, passiveIncome: 59650 },
      { nextLevelXP: 968540, passiveIncome: 92510 },
      { nextLevelXP: 1502600, passiveIncome: 143500 },
      { nextLevelXP: 2331400, passiveIncome: 222780 },
      { nextLevelXP: 3617300, passiveIncome: 345550 },
      { nextLevelXP: 5614200, passiveIncome: 536640 }
    ]
  },
  'Rain Gear': {
    description: 'Waterproof clothing and accessories to keep you dry during storms.',
    icon: require('../../assets/images/mine/supp/rain_gear.png'),
    levels: [
      { nextLevelXP: 1310, passiveIncome: 130 },
      { nextLevelXP: 2055, passiveIncome: 210 },
      { nextLevelXP: 3210, passiveIncome: 315 },
      { nextLevelXP: 5000, passiveIncome: 500 },
      { nextLevelXP: 7805, passiveIncome: 760 },
      { nextLevelXP: 12000, passiveIncome: 1170 },
      { nextLevelXP: 18720, passiveIncome: 1830 },
      { nextLevelXP: 29100, passiveIncome: 2840 },
      { nextLevelXP: 45260, passiveIncome: 4410 },
      { nextLevelXP: 70220, passiveIncome: 6810 },
      { nextLevelXP: 108980, passiveIncome: 10630 },
      { nextLevelXP: 169040, passiveIncome: 16190 },
      { nextLevelXP: 262560, passiveIncome: 24870 },
      { nextLevelXP: 407740, passiveIncome: 38620 },
      { nextLevelXP: 633120, passiveIncome: 60000 },
      { nextLevelXP: 982340, passiveIncome: 93110 },
      { nextLevelXP: 1523200, passiveIncome: 144470 },
      { nextLevelXP: 2361700, passiveIncome: 223930 },
      { nextLevelXP: 3658100, passiveIncome: 347130 },
      { nextLevelXP: 5668800, passiveIncome: 539610 }
    ]
  },
  'Insect Repellent': {
    description: 'Spray or cream to protect against insects. Helps avoid bites and diseases.',
    icon: require('../../assets/images/mine/supp/insect_repellent.png'),
    levels: [
      { nextLevelXP: 1420, passiveIncome: 140 },
      { nextLevelXP: 2230, passiveIncome: 225 },
      { nextLevelXP: 3500, passiveIncome: 350 },
      { nextLevelXP: 5480, passiveIncome: 545 },
      { nextLevelXP: 8580, passiveIncome: 850 },
      { nextLevelXP: 13420, passiveIncome: 1330 },
      { nextLevelXP: 21040, passiveIncome: 2080 },
      { nextLevelXP: 32930, passiveIncome: 3230 },
      { nextLevelXP: 51670, passiveIncome: 5080 },
      { nextLevelXP: 81040, passiveIncome: 7960 },
      { nextLevelXP: 127070, passiveIncome: 12480 },
      { nextLevelXP: 199250, passiveIncome: 19580 },
      { nextLevelXP: 312600, passiveIncome: 30870 },
      { nextLevelXP: 490350, passiveIncome: 48430 },
      { nextLevelXP: 769150, passiveIncome: 75950 },
      { nextLevelXP: 1207400, passiveIncome: 118930 },
      { nextLevelXP: 1894600, passiveIncome: 186780 },
      { nextLevelXP: 2972500, passiveIncome: 293100 },
      { nextLevelXP: 4658400, passiveIncome: 459400 },
      { nextLevelXP: 7293700, passiveIncome: 720100 }
    ]
  },
};

export { levelData, countries, investmentData, freelanceData, supplyStationData };