import React, { useEffect, useState } from 'react';
import startBg from './assets/images/start_bg.png';
import startText from './assets/images/start_text.png';
import './LoadingAnimation.css';

const LoadingAnimation = ({ onComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress >= 100) {
          clearInterval(interval);
          onComplete();
        }
        return nextProgress;
      });
    }, 40);

    return () => clearInterval(interval);
  }, [onComplete]);

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      position: 'relative', 
      height: '100vh', 
      backgroundColor: 'black',
      backgroundImage: `url(${startBg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center' 
    }}>
      <img 
        src={startText} 
        alt="Start Text" 
        className="start-text-image" 
      />
      <div className="start-loading-container"> 
        <div className="start-loading-text">Loading...</div>
        <div className="start-progress-bar-background">
          <div 
            className="start-progress-bar" 
            style={{ width: `${progress}%` }} 
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
