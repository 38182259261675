import React, { useEffect, useContext, useState } from 'react';
import './mine.css';
import coinImage from '../../assets/images/coin.png';
import { Equipment } from './equipment';
import { GameContext } from '../game/game_provider';
import { MusicContext } from '../../music_provider';
import { freelanceData, investmentData, supplyStationData } from '../game/game_model';

const formatNumber = (num) => {
  if (num < 1000) {
    return num.toFixed(0);
  } else if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
};

const Mine = () => {
  const { user } = useContext(GameContext);
  const { setMusic } = useContext(MusicContext);
  const balance = user.token_amount;
  const profitPerHour = user.mining_rate;
  const [selectedTab, setSelectedTab] = useState('INV');

  useEffect(() => {
    setMusic(require('../../assets/bgm/invite_friends.mp3'));
  }, [setMusic]);

  const renderContent = () => {
    switch (selectedTab) {
      case 'INV':
        return <Equipment type="inv" data={investmentData} title="Investment" />;
      case 'FREEL':
        return <Equipment type="freel" data={freelanceData} title="Freelance" />;
      case 'SUPP':
        return <Equipment type="supp" data={supplyStationData} title="Supply Station" />;
      default:
        return null;
    }
  };

  return (
    <div className="mine">
      <div className="score-box">
        <div className="section">
          <p className="title">Balance</p>
          <div className="value-container">
            <img src={coinImage} alt="Coin" className="coin" />
            <p className="value">{formatNumber(balance)}</p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="section">
          <p className="title">Profit/Hour</p>
          <div className="value-container">
            <img src={coinImage} alt="Coin" className="coin" />
            <p className="value">+{formatNumber(profitPerHour)}</p>
          </div>
        </div>
      </div>
      
      <div className="tab-container">
        <div
          className={`tab ${selectedTab === 'INV' ? 'selected' : ''}`}
          onClick={() => setSelectedTab('INV')}
        >
          INV
        </div>
        <div
          className={`tab ${selectedTab === 'FREEL' ? 'selected' : ''}`}
          onClick={() => setSelectedTab('FREEL')}
        >
          FREEL
        </div>
        <div
          className={`tab ${selectedTab === 'SUPP' ? 'selected' : ''}`}
          onClick={() => setSelectedTab('SUPP')}
        >
          SUPP
        </div>
      </div>
      
      <div className="content-section">
        {renderContent()}
      </div>
    </div>
  );
};

export default Mine;
