// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transition-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.game-container.transition .transition-mask {
  opacity: 1;
}

.game-container.transition .spotlight {
  animation: none;
}

.level-up-popup {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 327px;
  height: 40px;
  background-color: #7F5B5B5B;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.level-up-popup-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.level-up-popup-icon {
  width: 20px;
  height: 20px;
}

.level-up-popup-text {
  font-family: 'Press Start 2P';
  font-size: 10px;
  font-weight: 500;
  color: white;
  margin-left: 8px;
  transform: scaleY(1.25);
}

.level-up-popup.show {
  opacity: 1;
  pointer-events: auto;
  z-index: 1000;
}

.level-up-popup-close-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/game/game.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,UAAU;EACV,oCAAoC;EACpC,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,yCAAyC;EACzC,UAAU;EACV,oCAAoC;EACpC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;AACd","sourcesContent":[".transition-mask {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity 0.3s ease-in-out;\n  z-index: 1000;\n}\n\n.game-container.transition .transition-mask {\n  opacity: 1;\n}\n\n.game-container.transition .spotlight {\n  animation: none;\n}\n\n.level-up-popup {\n  position: fixed;\n  top: 10px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 327px;\n  height: 40px;\n  background-color: #7F5B5B5B;\n  border-radius: 36px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 16px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  opacity: 0;\n  transition: opacity 0.5s ease-in-out;\n  pointer-events: none;\n}\n\n.level-up-popup-content {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.level-up-popup-icon {\n  width: 20px;\n  height: 20px;\n}\n\n.level-up-popup-text {\n  font-family: 'Press Start 2P';\n  font-size: 10px;\n  font-weight: 500;\n  color: white;\n  margin-left: 8px;\n  transform: scaleY(1.25);\n}\n\n.level-up-popup.show {\n  opacity: 1;\n  pointer-events: auto;\n  z-index: 1000;\n}\n\n.level-up-popup-close-icon {\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
