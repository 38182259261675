import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import './tap_game.css';
import { GameInfo, ClickEffect, LevelBar, EnergyBar, LevelUpModal } from './tap_game_ui';
import { levelData, countries } from './game_model';
import gameTraveler from '../../assets/images/traveler_game.png';
import { levelUp } from './tap_game_api';
import { isTMA } from '@tma.js/sdk';
import { GameContext } from './game_provider';
import { MusicContext } from '../../music_provider';
const originalWidth = 1600;
const moveStep = 5;

const TapGame = ({ onBoost, onMenu }) => {
  const { user, setUser, energy, setEnergy, addToIncrAmount, isLoading } = useContext(GameContext);
  const { setMusic } = useContext(MusicContext);
  const [score, setScore] = useState(user.token_amount);
  const [travelerLevel, setTravelerLevel] = useState(user.user_level);
  const [tapScoreLevel, setTapScoreLevel] = useState(user.tap_to_earn_level);
  const [clickEffects, setClickEffects] = useState([]);
  const [imagePosition, setImagePosition] = useState(-originalWidth / 2);
  const [transitionStyle, setTransitionStyle] = useState('transform 0.3s linear');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldCallOnMenu, setShouldCallOnMenu] = useState(false);

  const tapgameRef = useRef(null);
  const isTouchDeviceRef = useRef(false);
  const travelerRef = useRef(null);

  const maxEnergy = levelData.energyRecovery[user.energy_level].maxEnergy;

  const backgroundImage = countries[user.current_country]?.map;

  const earnPerTap = levelData.traveler[travelerLevel - 1].earnPerTap + levelData.tapScore[tapScoreLevel].earnPerTap;

  const handleUpdateScore = useCallback(() => {
    if (energy >= earnPerTap) {
      const newEnergy = Math.max(0, energy - earnPerTap);
      setEnergy(newEnergy);

      const newScore = score + earnPerTap;
      let newLevel = travelerLevel;

      if (newScore >= levelData.traveler[newLevel - 1].nextLevelXP) {
        newLevel += 1;
        const requireAmount = levelData.traveler[travelerLevel - 1].nextLevelXP;
        const type = "user";

        setIsModalVisible(true);
        setShouldCallOnMenu(true);
        setTravelerLevel(newLevel);

        levelUp(requireAmount, newLevel, type).then(() => {
          setUser(prevUser => ({
            ...prevUser,
            user_level: newLevel
          }));
        });
      }

      setScore(newScore);
      addToIncrAmount(earnPerTap);
      setUser(prevUser => ({
        ...prevUser,
        token_amount: newScore
      }));
    }
  }, [energy, earnPerTap, travelerLevel, score, setUser, setEnergy, addToIncrAmount]);

  const handleInteraction = useCallback((x, y) => {
    const newEffects = [{ id: Date.now() + Math.random(), x, y }];
    setClickEffects(prevEffects => [...prevEffects, ...newEffects]);
    handleUpdateScore();

    setTimeout(() => {
      setClickEffects(prevEffects => prevEffects.filter(effect => !newEffects.find(newEffect => newEffect.id === effect.id)));
    }, 1000);

    if (travelerRef.current) {
      travelerRef.current.classList.add('shrink-animation');
      setTimeout(() => {
        travelerRef.current.classList.remove('shrink-animation');
      }, 200);
    }
    if (isTMA() && window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }

    setImagePosition(prevPosition => {
      const newPosition = prevPosition + moveStep;
      if (newPosition >= originalWidth / 2) {
        setTransitionStyle('none');
        setTimeout(() => {
          setImagePosition(-originalWidth / 2);
          setTimeout(() => {
            setTransitionStyle('transform 0.3s linear');
          }, 50);
        }, 50);
        return newPosition;
      }
      return newPosition;
    });
  }, [handleUpdateScore]);

  const handleTouchStart = useCallback(() => {
    isTouchDeviceRef.current = true;
  }, []);

  const handleTouchEnd = useCallback((e) => {
    if (tapgameRef.current && energy >= earnPerTap) {
      const rect = tapgameRef.current.getBoundingClientRect();
      for (let touch of e.changedTouches) {
        handleInteraction(
          touch.clientX - rect.left,
          touch.clientY - rect.top
        );
      }
    }
  }, [energy, earnPerTap, handleInteraction]);

  const handleMouseUp = useCallback((e) => {
    if (!isTouchDeviceRef.current && tapgameRef.current && energy >= earnPerTap) {
      const rect = tapgameRef.current.getBoundingClientRect();
      handleInteraction(
        e.clientX - rect.left,
        e.clientY - rect.top
      );
    }
  }, [energy, earnPerTap, handleInteraction]);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);

    setUser(prevUser => {
      const updatedSelectedCountries = [...(prevUser.selected_countries || [])];
      if (!updatedSelectedCountries.includes(prevUser.current_country)) {
        updatedSelectedCountries.push(prevUser.current_country);
      }

      return {
        ...prevUser,
        selected_countries: updatedSelectedCountries
      };
    });

    if (shouldCallOnMenu) {
      onMenu();
      setShouldCallOnMenu(false);
    }
  }, [setUser, onMenu, shouldCallOnMenu]);



  const isMaxLevel = travelerLevel === levelData.traveler.length;
  const nextLevelXP = isMaxLevel ? 0 : levelData.traveler[travelerLevel - 1].nextLevelXP;

  useEffect(() => {
    setMusic(countries[user.current_country]?.bgm);
  }, [user.current_country, setMusic]);

  useEffect(() => {
    setScore(user.token_amount);
  }, [user.token_amount]);

  useEffect(() => {
    setTravelerLevel(user.user_level);
  }, [user.user_level]);

  useEffect(() => {
    setTapScoreLevel(user.tap_to_earn_level);
  }, [user.tap_to_earn_level]);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tap-game" ref={tapgameRef}>
      <div className="background-map-container">
        <div
          className="background-map-wrapper"
          style={{
            transform: `translateX(${imagePosition}px)`,
            transition: transitionStyle
          }}
        >
          {backgroundImage && (
            <>
              <img
                src={backgroundImage}
                alt={user.current_country}
                className="background-map"
                style={{ width: originalWidth }}
              />
              <img
                src={backgroundImage}
                alt={user.current_country}
                className="background-map"
                style={{ width: originalWidth }}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ height: '32px' }}></div>
      <GameInfo
        earnPerTap={earnPerTap}
        nextLevelXP={nextLevelXP}
        isMaxLevel={isMaxLevel}
        score={score}
      />
      <div
        className="game_traveler-container"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={handleMouseUp}
      >
        <div className="game_traveler-clickable-area"></div>
        <img
          src={gameTraveler}
          alt="traveler"
          className="game_traveler"
          ref={travelerRef}
        />
      </div>
      {clickEffects.map(effect => (
        <ClickEffect key={effect.id} x={effect.x} y={effect.y} earnPerTap={earnPerTap} />
      ))}
      <LevelBar
        level={travelerLevel}
        score={score}
        nextLevelXP={nextLevelXP}
        levelTitles={levelData.traveler.map(level => level.title)}
        isMaxLevel={isMaxLevel}
      />
      <div style={{ height: '26px' }}></div>
      <EnergyBar
        currentEnergy={energy}
        maxEnergy={maxEnergy}
        onBoost={onBoost}
      />
      <div style={{ height: '12px' }}></div>
      <LevelUpModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default TapGame;