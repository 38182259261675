import React, { useContext } from 'react';
import './tap_game.css';
import coinIcon from '../../assets/images/coin.png';
import water from '../../assets/images/water.png';
import turbo from '../../assets/images/turbo.png';
import levelUpImage from '../../assets/images/levelup.png';
import { GameContext } from '../game/game_provider';

const LevelUpModal = ({ isVisible, onClose }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="level-up-modal" onClick={(e) => e.stopPropagation()}>
            <img src={levelUpImage} alt="Level Up" className="level-up-background" />
            <div className="level-up-content">
                <button className="level-up-button" onClick={onClose}>
                    <span className="level-up-button-text">Next Journey</span>
                </button>
                <div className="level-up-text">Level Up !!!</div>
            </div>
        </div>
    );
};

const formatNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
    return num.toString();
};

const GameInfo = ({ earnPerTap, nextLevelXP, isMaxLevel, score }) => {
    const { user } = useContext(GameContext);
    const miningRate = formatNumber(user.mining_rate);

    return (
        <div className="game-info">
            <div className="info-row">
                <InfoItem title="Earn Per Tap" value={`+${earnPerTap}`} coinIcon={coinIcon} />
                <Divider />
                <InfoItem title="Profit/Hour" value={`+${miningRate}`} coinIcon={coinIcon} />
                <Divider />
                <InfoItem title="Coin To Level Up" value={isMaxLevel ? "Level MAX" : formatNumber(nextLevelXP)} />
            </div>
            <div className="horizontal-divider"></div>
            <Scoreboard score={score.toFixed(0)} />
        </div>
    );
};

const InfoItem = ({ title, value, coinIcon }) => (
    <div className="info-item">
        <div className="column">
            <span className="title">{title}</span>
            <div style={{ height: '8px' }}></div>
            <div className="info-value">
                {coinIcon && <img src={coinIcon} alt="Coin Icon" className="coin-icon" />}
                <span className="value">{value}</span>
            </div>
        </div>
    </div>
);

const Divider = () => <div className="divider"></div>;

const Scoreboard = ({ score }) => {
    const formattedScore = new Intl.NumberFormat('en-US').format(score);

    return (
        <div className="scoreboard">
            <img src={coinIcon} alt="Coin Icon" className="scoreboard-coin-icon" />
            <span className="score">{formattedScore}</span>
        </div>
    );
};

const ClickEffect = ({ x, y, earnPerTap }) => (
    <div className="click-effect" style={{ top: `${y}px`, left: `${x}px` }}>
        +{earnPerTap}
    </div>
);

const LevelBar = ({ level, score, nextLevelXP, levelTitles, isMaxLevel }) => {
    const progressPercentage = isMaxLevel ? 100 : (score / nextLevelXP) * 100;
    const levelTitle = levelTitles[level - 1] || "Unknown";

    return (
        <div className="level-bar">
            <div className="level-info">
                <span className="level-title">{levelTitle}</span>
                <span className="level-progress">{isMaxLevel ? "Level MAX" : `Level ${level}/${levelTitles.length}`}</span>
            </div>
            <div className="progress-bar-background">
                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            </div>
        </div>
    );
};

const EnergyBar = ({ currentEnergy, maxEnergy, onBoost }) => (
    <div className="energy-bar">
        <div className="energy-info">
            <img src={water} alt="Energy Icon" className="energy-icon" />
            <span className="energy-text">{`${currentEnergy}/${maxEnergy}`}</span>
        </div>
        <button className="boost-info" onClick={onBoost}>
            <img src={turbo} alt="Turbo Icon" className="boost-icon" />
            <span className="boost-text">Boost</span>
        </button>
    </div>
);

export {
    GameInfo,
    InfoItem,
    Divider,
    Scoreboard,
    ClickEffect,
    LevelBar,
    EnergyBar,
    LevelUpModal
};
