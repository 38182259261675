// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-loading-container {
    position: absolute;
    bottom: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.start-progress-bar-background {
    width: 100%;
    max-width: 300px;
    height: 8px;
    background-color: #272A2F;
    border-radius: 4px;
    margin-top: 4px;
    overflow: hidden;
    box-shadow: 0 0 0 2px #272A2F;
    animation: slideInFromLeft 0.4s ease-out;
  }
  
  .start-progress-bar {
    height: 100%;
    background: linear-gradient(to right, #007cf0, #00dfd8);
    border-radius: 4px;
    animation: slideInFromLeft 0.4s ease-out;
  }
  
  .start-loading-text {
    font-family: 'Press Start 2P';
    color: white; 
    font-size: 13px;
    transform: scaleY(1.25);
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px; 
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .start-text-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    height: 230px;
    z-index: 1;
    animation: fadeIn 1s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/LoadingAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;AAEF;IACI,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,wCAAwC;EAC1C;;EAEA;IACE,YAAY;IACZ,uDAAuD;IACvD,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gCAAgC;EAClC;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,UAAU;IACV,gCAAgC;AACpC","sourcesContent":[".start-loading-container {\n    position: absolute;\n    bottom: 90px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n.start-progress-bar-background {\n    width: 100%;\n    max-width: 300px;\n    height: 8px;\n    background-color: #272A2F;\n    border-radius: 4px;\n    margin-top: 4px;\n    overflow: hidden;\n    box-shadow: 0 0 0 2px #272A2F;\n    animation: slideInFromLeft 0.4s ease-out;\n  }\n  \n  .start-progress-bar {\n    height: 100%;\n    background: linear-gradient(to right, #007cf0, #00dfd8);\n    border-radius: 4px;\n    animation: slideInFromLeft 0.4s ease-out;\n  }\n  \n  .start-loading-text {\n    font-family: 'Press Start 2P';\n    color: white; \n    font-size: 13px;\n    transform: scaleY(1.25);\n    font-weight: 700;\n    text-align: center;\n    margin-bottom: 10px; \n    animation: fadeIn 1s ease-in-out;\n  }\n  \n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n  \n  .start-text-image {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 230px;\n    height: 230px;\n    z-index: 1;\n    animation: fadeIn 1s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
