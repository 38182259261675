import React, { useState, useEffect, useContext } from 'react';
import './boost.css';
import coinIcon from '../../assets/images/coin.png';
import waterIcon from '../../assets/images/water.png';
import multiTapIcon from '../../assets/images/multi_tap.png';
import energyLimitIcon from '../../assets/images/energy_limit.png';
import rightArrowIcon from '../../assets/icons/arrow-circle-right.svg';
import closeIcon from '../../assets/icons/close.svg';
import { levelData } from '../game/game_model';
import { levelUp, rechargeEnergy } from './tap_game_api';
import { GameContext } from '../game/game_provider';
import { MusicContext } from '../../music_provider';

const formatNumber = (num) => {
    if (num < 1000) {
      return num.toFixed(0);
    } else if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
  };  

const Boost = ({ onBack, onLevelUpSuccess }) => {
    const { user, setUser, setEnergy, sendMessageNow } = useContext(GameContext);
    const { setMusic } = useContext(MusicContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedBooster, setSelectedBooster] = useState(null);
    const [currentTapScoreLevel, setCurrentTapScoreLevel] = useState(levelData.tapScore[user.tap_to_earn_level]);
    const [currentEnergyRecoveryLevel, setCurrentEnergyRecoveryLevel] = useState(levelData.energyRecovery[user.energy_level]);
    const [currentTokenAmount, setCurrentTokenAmount] = useState(user.token_amount);
    const [loading, setLoading] = useState(false);

    const { recharge_energy_times } = user;

    useEffect(() => {
        setCurrentTapScoreLevel(levelData.tapScore[user.tap_to_earn_level]);
        setCurrentEnergyRecoveryLevel(levelData.energyRecovery[user.energy_level]);
        setCurrentTokenAmount(user.token_amount);
    }, [user]);

    useEffect(() => {
        setMusic(require('../../assets/bgm/boost.mp3'));
    }, [setMusic]);

    const handleBoosterClick = (booster) => {
        setSelectedBooster(booster);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        if (!loading) {  
            setShowModal(false);
        }
    };

    const handleBoostAction = async () => {
        try {
            setLoading(true);
            let type;
            let requireAmount;
            let targetLevel;
            let boosterText;

            if (selectedBooster === 'Multitap') {
                type = 'tap';
                requireAmount = currentTapScoreLevel.nextLevelXP;
                targetLevel = user.tap_to_earn_level + 1;
                boosterText = `Multitap Lvl ${targetLevel}!`;
            } else if (selectedBooster === 'Energy limit') {
                type = 'energy';
                requireAmount = currentEnergyRecoveryLevel.nextLevelXP;
                targetLevel = user.energy_level + 1;
                boosterText = `Energy limit Lvl ${targetLevel}!`;
                setEnergy(levelData.energyRecovery[user.energy_level + 1].maxEnergy);
            } else if (selectedBooster === 'Full Energy') {
                boosterText = 'Full Energy!';

                const response = await rechargeEnergy();
                if (response.code === 0) {
                    setEnergy(levelData.energyRecovery[user.energy_level].maxEnergy);
                    setUser(prevUser => ({
                        ...prevUser,
                        recharge_energy_times: prevUser.recharge_energy_times - 1,
                    }));
                } else {
                    console.error('Failed to recharge energy:', response.message);
                }

                setLoading(false);
                setShowModal(false);
                onBack();
                onLevelUpSuccess(boosterText);
                return;
            }

            if (currentTokenAmount < requireAmount) {
                setLoading(false);
                return;
            }

            sendMessageNow();

            const { response } = await levelUp(requireAmount, targetLevel, type);

            if (response.code === 0 && response.result === 'success') {
                setUser(prevUser => ({
                    ...prevUser,
                    token_amount: prevUser.token_amount - requireAmount,
                    [type === 'tap' ? 'tap_to_earn_level' : 'energy_level']: targetLevel
                }));
                setShowModal(false);
                onBack();
                onLevelUpSuccess(boosterText);
            } else {
                throw new Error('Level up failed, please try again.');
            }

        } catch (error) {
            console.error('Error processing boost action:', error);
            alert('Failed to complete the action. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const boosters = {
        'Full Energy': {
            icon: waterIcon,
            title: 'Full Energy',
            description: 'Refill your energy to the max and embark on\nanother exciting adventure!',
            available: recharge_energy_times,
        },
        'Multitap': {
            icon: multiTapIcon,
            title: 'Multitap',
            nextLevel: user.tap_to_earn_level + 1,
            nextLevelXP: currentTapScoreLevel.nextLevelXP,
            description: 'Amount value per coin +1 for each level'
        },
        'Energy limit': {
            icon: energyLimitIcon,
            title: 'Energy limit',
            nextLevel: user.energy_level + 1,
            nextLevelXP: currentEnergyRecoveryLevel.nextLevelXP,
            description: 'Amount value per energy +1000 for each level'
        }
    };

    const renderModalContent = () => {
        if (!selectedBooster) return null;
        const booster = boosters[selectedBooster];
        const requireAmount = booster.nextLevelXP;
        const isDisabled = (selectedBooster !== 'Full Energy' && currentTokenAmount < requireAmount) ||
            (selectedBooster === 'Full Energy' && recharge_energy_times === 0);

        return (
            <div className="boost-modal-content">
                <img src={closeIcon} alt="close" className="boost-modal-close" onClick={handleCloseModal} />
                <img src={booster.icon} alt={booster.title} className="boost-modal-icon" />
                <div className="boost-modal-title">{booster.title}</div>
                <div className="boost-modal-description">
                    {booster.description}
                </div>
                <div className="boost-modal-next-level">
                    <img src={coinIcon} alt="coin" className="boost-modal-coin-icon" />
                    {selectedBooster !== 'Full Energy' ? (
                        <>
                            {formatNumber(booster.nextLevelXP)} | Lvl {booster.nextLevel}
                        </>
                    ) : (
                        `${recharge_energy_times}/3 Available`
                    )}
                </div>
                <button
                    className={`boost-modal-button ${isDisabled || loading ? 'disabled' : ''}`}
                    onClick={handleBoostAction}
                    disabled={isDisabled || loading}
                >
                    {loading ? 'Processing...' : (selectedBooster === 'Full Energy' ? 'Get Energy' : 'Level Up')}
                </button>

            </div>
        );
    };

    return (
        <div className="boost">
            <div className="boost-balance">Your balance</div>
            <div className="boost-scoreboard">
                <img src={coinIcon} alt="coin" className="boost-scoreboard-coin-icon" />
                <div className="boost-score">{formatNumber(currentTokenAmount)}</div>
            </div>
            <div className="boost-boosters-container">
                <div className="boost-boosters-title">Free daily boosters</div>
                <div className="boost-boosters-item" onClick={() => handleBoosterClick('Full Energy')}>
                    <img src={waterIcon} alt="water" />
                    <div className="boost-boosters-item-content">
                        <div className="title">Full Energy</div>
                        <div className="subtitle">{recharge_energy_times}/3 Available</div>
                    </div>
                </div>
                <div className="boost-boosters-title">Boosters</div>
                <div className="boost-boosters-item" onClick={() => handleBoosterClick('Multitap')}>
                    <img src={multiTapIcon} alt="multiTap" />
                    <div className="boost-boosters-item-content">
                        <div className="title">Multitap</div>
                        {currentTapScoreLevel ? (
                            <div className="subtitle subtitle-white">
                                <img src={coinIcon} alt="coin" />
                                {formatNumber(currentTapScoreLevel.nextLevelXP)} | Lvl {user.tap_to_earn_level + 1}
                            </div>
                        ) : (
                            <div className="subtitle subtitle-white">
                                Max Level
                            </div>
                        )}
                    </div>
                    <img src={rightArrowIcon} alt="arrow right" className="arrow-icon" />
                </div>
                <div className="boost-boosters-item" onClick={() => handleBoosterClick('Energy limit')}>
                    <img src={energyLimitIcon} alt="energyLimit" />
                    <div className="boost-boosters-item-content">
                        <div className="title">Energy limit</div>
                        {currentEnergyRecoveryLevel ? (
                            <div className="subtitle subtitle-white">
                                <img src={coinIcon} alt="coin" />
                                {formatNumber(currentEnergyRecoveryLevel.nextLevelXP)} | Lvl {user.energy_level + 1}
                            </div>
                        ) : (
                            <div className="subtitle subtitle-white">
                                Max Level
                            </div>
                        )}
                    </div>
                    <img src={rightArrowIcon} alt="arrow right" className="arrow-icon" />
                </div>
            </div>
            <div className={`boost-modal-container ${showModal ? 'show' : ''}`}>
                {renderModalContent()}
            </div>
        </div>
    );
};

export default Boost;
