import api from '../../api';

export const verifyTaskWithCode = async (code) => {
    try {
      const response = await api.apiPost('telegram/game/daily_task/post/verify', { code });
      return response;
    } catch (error) {
      console.error('Error verifying task with code:', error);
      throw error;
    }
  };

  export const completeSingleTask = async (type, code = null) => {
    try {
      const payload = { type };
      if (code) {
        payload.code = code;
      }
      const response = await api.apiPost('telegram/game/single_task/complete', payload);
      return response;
    } catch (error) {
      console.error('Error completing single task:', error);
      throw error;
    }
  };

  export const signInDailyTask = async () => {
    try {
        const response = await api.apiPost('telegram/game/daily_task/sign_in');
        return response;
    } catch (error) {
        console.error('Error signing in daily task:', error);
        throw error;
    }
};

const earnApi = {
  verifyTaskWithCode,
  completeSingleTask,
  signInDailyTask
};

export default earnApi;
