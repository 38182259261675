import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import './App.css';
import LoadingAnimation from './LoadingAnimation';
import { MusicProvider } from './music_provider';
import { GameProvider } from './pages/game/game_provider';
import config from './config';

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);

  const isTelegramEnvironment = () => {
    return (
      typeof window !== 'undefined' &&
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.initData &&
      window.Telegram.WebApp.initData.length > 0
    );
  };

  const getStartParam = () => {
    if (isTelegramEnvironment()) {
      return window.Telegram.WebApp.initDataUnsafe.start_param || '';
    } else {
      return new URLSearchParams(window.location.search).get('start_param') || '';
    }
  };

  const startParam = getStartParam();

  useEffect(() => {
    const checkLogin = async () => {
      if (loginAttempted) {
        console.log('Login already attempted');
        return;
      }

      setLoginAttempted(true);
      try {
        let initDataRaw;
        const isInTelegram = isTelegramEnvironment();
        console.log(`Running in ${isInTelegram ? 'Telegram' : 'non-Telegram'} environment`);

        if (isInTelegram) {
          initDataRaw = window.Telegram.WebApp.initData;
        } else {
          initDataRaw = 'user=%7B%22id%22%3A7329783310%2C%22first_name%22%3A%22Thala%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=4806735066151587089&chat_type=sender&auth_date=1728477049&hash=12f1bee30ab9c9afa3fec41f75058ec5cabbe0618743ac8b211ee5e78396dea8';
        }

        const TG_LOGIN_URL = `https://${config.apiUrl}telegram/users/tg_login`;
        console.log('Attempting login to:', TG_LOGIN_URL);
        
        const headers = new Headers({
          'Authorization': `tma ${initDataRaw}`,
          'Content-Type': 'application/json',
        });

        const requestBody = JSON.stringify({
          invite_code: startParam,
        });

        console.log('Request headers:', Object.fromEntries(headers.entries()));
        console.log('Request body:', requestBody);

        const response = await fetch(TG_LOGIN_URL, {
          method: 'POST',
          headers: headers,
          body: requestBody,
        });

        const responseData = await response.text();

        if (response.ok) {
          const data = JSON.parse(responseData);
          console.log('Login successful:', data);
          localStorage.setItem('token', data.result.token);
          localStorage.setItem('userInfo', JSON.stringify(data.result.telegram));
        } else {
          console.error('Login failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during login:', error);
      } finally {
        if (isTelegramEnvironment()) {
          window.Telegram.WebApp.expand();
        }
        setPageLoaded(true);
      }
    };

    checkLogin();
  }, [startParam, loginAttempted]);

  useEffect(() => {
    if (pageLoaded && animationComplete) {
      setLoading(false);
    }
  }, [pageLoaded, animationComplete]);

  useEffect(() => {
    const preventCollapse = (event) => {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    };

    document.addEventListener("touchstart", preventCollapse);

    return () => {
      document.removeEventListener("touchstart", preventCollapse);
    };
  }, []);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  if (loading) {
    return <LoadingAnimation onComplete={handleAnimationComplete} />;
  }

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', overflow: 'hidden' }}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={
              <GameProvider>
                <Home />
              </GameProvider>
            } />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

const App = () => (
  <MusicProvider>
    <AppContent />
  </MusicProvider>
);

export default App;