import api from '../../api';

export const levelUpEquipment = async (name) => {
    try {
      const response = await api.apiPost('telegram/game/equipment/levelup', {
        name,
      });
      return response;
    } catch (error) {
      console.error('Error leveling up equipment:', error);
      throw error;
    }
  };

const mineApi = {
  levelUpEquipment,
};

export default mineApi;