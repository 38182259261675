import React, { useState, useEffect, useContext } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import './home.css';
import lottieLogo from '../../assets/lottie/thala_appbar.json';
import headshot from '../../assets/images/headshot.png';
import volumeOn from '../../assets/images/volume_on.png';
import volumeOff from '../../assets/images/volume_off.png';
import Game from '../game/game.js';
import InviteFriends from '../invite_friends/invite_friends.js';
import Mine from '../mine/mine.js';
import Earn from '../earn/earn.js';
import NavBar from '../nav_bar/NavBar.js';

import { GameContext } from '../game/game_provider.js';
import { MusicContext } from '../../music_provider';

const AppBar = ({ lottieLogo, headshot, userName }) => {
  const { isMusicPlaying, toggleMusic } = useContext(MusicContext);

  return (
    <div className="AppBar">
      <Player autoplay loop src={lottieLogo} style={{ height: '27px', width: '120px' }} />
      <div className="spacer"></div>
      <span className="user-name">{userName}</span>
      <div style={{ width: '8px' }}></div>
      <img src={headshot} alt="User Headshot" className="headshot" />
      <div style={{ width: '8px' }}></div>
      <img
        src={isMusicPlaying ? volumeOn : volumeOff}
        alt="Volume Control"
        className="volume-button"
        onClick={toggleMusic}
      />
    </div>
  );
};

const Home = () => {
  const [selectedTab, setSelectedTab] = useState('Tap');
  const [userName, setUserName] = useState('unknown user');
  const [showAppBar, setShowAppBar] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [background, setBackground] = useState('default');
  const { sendMessageNow } = useContext(GameContext);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      setUserName(`${userInfo.first_name} ${userInfo.last_name}`);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === 'Friends') {
      setShowAppBar(false);
    } else {
      setShowAppBar(true);
    }
  }, [selectedTab]);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const handleTabSelect = (tabName) => {
    setIsTransitioning(true);
    setBackground('default');
    sendMessageNow();
    setTimeout(() => {
      setSelectedTab(tabName);
      setIsTransitioning(false);
    }, 300);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'Earn':
        return <div className="tab-content"><Earn/></div>;
      case 'Friends':
        return <div className="tab-content"><InviteFriends /></div>;
      case 'Tap':
        return (
          <div className="tab-content" onTouchMove={preventDefault} style={{ touchAction: 'none' }}>
            <Game setShowAppBar={setShowAppBar} setBackground={setBackground} />
          </div>
        );
      case 'Mine':
        return <div className="tab-content"><Mine/></div>;
      case 'Airdrop':
        return <div className="tab-content">Airdrop Content</div>;
      default:
        return null;
    }
  };

  return (
      <div className={`home ${background === 'menu' ? 'menu-background' : ''}`}>
        {showAppBar && <AppBar lottieLogo={lottieLogo} headshot={headshot} userName={userName} />}
        <div className="container">
            <div className={`column ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
              {renderContent()}
          </div>
        </div>
        <div className="navbar-container">
          <NavBar selected={selectedTab} onSelect={handleTabSelect} />
          <div className="navbar-placeholder"></div>
        </div>
      </div>
  );
};

export default Home;