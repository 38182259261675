import api from '../../api';

export const getUserInfo = async () => {
  try {
    const userInfo = await api.apiGet('telegram/game/user_info');
    return userInfo;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

export const levelUp = async (requireAmount, targetLevel, type) => {
  try {
    const payload = {
      require_amount: requireAmount,
      target_level: targetLevel,
      type: type,
    };
    const response = await api.apiPost('telegram/game/levelup', payload);
    return { response, payload };
  } catch (error) {
    console.error('Error leveling up:', error);
    throw error;
  }
};

export const selectCountry = async (country) => {
  try {
    const payload = { country };
    const response = await api.apiPost('telegram/game/select_country', payload);
    return response;
  } catch (error) {
    console.error('Error selecting country:', error);
    throw error;
  }
};

export const rechargeEnergy = async () => {
  try {
    const response = await api.apiPost('telegram/game/recharge_energy');
    return response;
  } catch (error) {
    console.error('Error recharging energy:', error);
    throw error;
  }
};

const tapGameApi = {
  getUserInfo,
  levelUp,
  selectCountry,
  rechargeEnergy,
};

export default tapGameApi;
