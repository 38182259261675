import React, { useEffect, useRef } from 'react';
import coinImage from '../../assets/images/coin.png';

const CoinAnimationCanvas = ({ trigger, coinCount = 50, duration = 1000 }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (trigger) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            let animationFrameId;
            let startTime;

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            const coins = [];

            const targetX = canvas.width / 2;
            const targetY = 0;

            for (let i = 0; i < coinCount; i++) {
                const startX = Math.random() * canvas.width;
                const startY = getRandomYPosition(canvas.height);
                coins.push({
                    startX,
                    startY,
                    x: startX,
                    y: startY,
                    alpha: 0.5,
                });
            }

            const coinImg = new Image();
            coinImg.src = coinImage;

            const render = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const progress = timestamp - startTime;

                ctx.clearRect(0, 0, canvas.width, canvas.height);

                coins.forEach((coin) => {
                    if (progress <= 500) {
                        const moveProgress = Math.min(progress / 500, 1);
                        coin.x = coin.startX + (targetX - coin.startX) * moveProgress;
                        coin.y = coin.startY + (targetY - coin.startY) * moveProgress;
                        coin.alpha = 0.5;
                    } else if (progress <= 1000) {
                        coin.x = targetX;
                        coin.y = targetY;
                        const fadeProgress = (progress - 500) / 500;
                        coin.alpha = 0.5 * (1 - fadeProgress);
                    } else {
                        coin.alpha = 0;
                    }

                    if (coin.alpha > 0) {
                        ctx.globalAlpha = coin.alpha;
                        ctx.drawImage(coinImg, coin.x - 15, coin.y - 15, 30, 30);
                        ctx.globalAlpha = 1;
                    }
                });

                if (progress < duration) {
                    animationFrameId = requestAnimationFrame(render);
                } else {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    cancelAnimationFrame(animationFrameId);
                }
            };

            animationFrameId = requestAnimationFrame(render);

            return () => {
                cancelAnimationFrame(animationFrameId);
            };
        }
    }, [trigger, coinCount, duration]);

    const getRandomYPosition = (height) => {
        const rand = Math.random();
        return height * (1 - Math.pow(rand, 2));
    };

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                pointerEvents: 'none',
                zIndex: 9999,
            }}
        />
    );
};

export default CoinAnimationCanvas;
