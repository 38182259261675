import React, { useState, useEffect, useContext } from 'react';
import './menu.css';
import earth from '../../assets/images/earth.png';
import traveler from '../../assets/images/traveler.png';
import locationIcon from '../../assets/icons/location.svg';
import goIcon from '../../assets/images/go.png';
import tickCircleIcon from '../../assets/icons/tick-circle.svg';
import { countries } from './game_model';
import tapGameApi from './tap_game_api';
import { GameContext } from './game_provider';
import { MusicContext } from '../../music_provider';

const Menu = () => {
  const { user, setUser } = useContext(GameContext);
  const { setMusic } = useContext(MusicContext);
  const [selectedCountry, setSelectedCountry] = useState('Select');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [disabledCountries, setDisabledCountries] = useState([]);

  useEffect(() => {
    if (user && user.selected_countries) {
      setDisabledCountries(user.selected_countries);
    }
  }, [user]);

  useEffect(() => {
    setMusic(require('../../assets/bgm/menu.mp3'));
  }, [setMusic]);

  const handleSelect = (country) => {
    if (!disabledCountries.includes(country)) {
      setSelectedCountry(country);
      setDropdownOpen(false);
    }
  };

  const handleStart = async () => {
    if (selectedCountry !== 'Select') {
      try {
        await tapGameApi.selectCountry(selectedCountry);
        setUser(prevUser => ({
          ...prevUser,
          current_country: selectedCountry,
        }));
      } catch (error) {
        console.error('Error selecting country:', error);
      }
    }
  };

  return (
    <div className="menu">
      <div className="menu-text">
        {disabledCountries.length > 0 ? (
          <> <div>Next Stop,</div>
            <div>Select a Country to Travel...</div>
          </>
        ) : (
          <>
            <div>First,</div>
            <div>Select Travel Country...</div>
          </>
        )}
      </div>
      <div className="menu-earth-container">
        <div className="menu-earth-halo"></div>
        <img src={earth} alt="earth" className="menu-earth" />
        <img src={traveler} alt="traveler" className="menu-traveler" />
        {selectedCountry !== 'Select' && (
          <img src={goIcon} alt="go icon" className="go-button" onClick={handleStart} />
        )}
      </div>
      <div className="menu-button-container">
        <button
          className={`menu-button ${selectedCountry !== 'Select' ? 'selected' : ''}`}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <span className={`button-text ${selectedCountry !== 'Select' ? 'selected' : ''}`}>
            {selectedCountry}
          </span>
          <img src={locationIcon} alt="location icon" className="location-icon" />
        </button>
        {dropdownOpen && (
          <ul className="dropdown-menu">
            {Object.keys(countries).map((country) => (
              <li
                key={country}
                onClick={() => handleSelect(country)}
                className={disabledCountries.includes(country) ? 'disabled' : ''}
              >
                <img
                  src={countries[country].flag}
                  alt={`${country} flag`}
                  className="country-flag"
                />
                <span className="text">{country}</span>
                {disabledCountries.includes(country) && (
                  <img
                    src={tickCircleIcon}
                    alt="tick icon"
                    className="tick-circle-icon"
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Menu;