import axios from 'axios';
import config from './config';

const BASE_URL = `https://${config.apiUrl}`; 

const apiClient = axios.create({
  baseURL: BASE_URL,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }
  console.log('Request config:', config);
  return config;
}, (error) => {
  return Promise.reject(error);
});

const apiGet = async (endpoint, params) => {
  try {
    const response = await apiClient.get(endpoint, { params });
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(`GET ${endpoint} error:`, error);
    throw error;
  }
};

const apiPost = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(`POST ${endpoint} error:`, error);
    throw error;
  }
};

const api = {
  apiGet,
  apiPost,
};

export default api;
